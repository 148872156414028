.hpm-App{
  .sl-tirelabel {
    position: relative;
    width: 10pc;
    height: 234px;
    margin-right: 15px;
    float: left;
  }
  .sl-tirelabel-fuel {
    left: 63px;
  }
  .sl-tirelabel-fuel, .sl-tirelabel-grip {
    position: absolute;
    top: 0;
    width: 24px;
    height: 21px;
    background: #333;
    color: #fff;
    line-height: 21px;
    font-size: 21px;
    text-align: center;
  }
  .sl-tirelabel-grip {
    left: 125px;
  }
  .sl-tirelabel-fuel span, .sl-tirelabel-grip span {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: -21px;
    width: 21px;
    height: 21px;
  }
  .sl-tirelabel-fuel span:before, .sl-tirelabel-grip span:before {
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    transform: rotate(45deg);
    width: 21px;
    height: 21px;
    background: #333;
  }
  .sl-tirelabel-fuel.sl-a, .sl-tirelabel-grip.sl-a {
    top: 63px;
  }
  .sl-tirelabel-fuel.sl-b, .sl-tirelabel-grip.sl-b {
    top: 74px;
  }
  .sl-tirelabel-fuel.sl-c, .sl-tirelabel-grip.sl-c {
    top: 86px;
  }
  .sl-tirelabel-fuel.sl-d, .sl-tirelabel-grip.sl-d {
    top: 98px;
  }
  .sl-tirelabel-fuel.sl-e, .sl-tirelabel-grip.sl-e {
    top: 110px;
  }
  .sl-tirelabel-fuel.sl-f, .sl-tirelabel-grip.sl-f {
    top: 121px;
  }
  .sl-tirelabel-fuel.sl-g, .sl-tirelabel-grip.sl-g {
    top: 99pt;
  }
  .sl-tirelabel-ico {
    position: absolute;
    top: 175px;
    left: 37px;
  }
  .sl-tirelabel-noise {
    position: absolute;
    top: 181px;
    right: 13px;
    width: 44px;
    height: 21px;
    background: #333;
    color: #fff;
    line-height: 21px;
    font-size: 1pc;
    text-align: center;
  }
  .sl-tirelabel-noise small {
    font-size: 11px;
    color: inherit;
  }
  .sl-tirelabel-noise span {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: -21px;
    width: 21px;
    height: 21px;
  }
  .sl-tirelabel-noise span:before {
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    transform: rotate(45deg);
    width: 21px;
    height: 21px;
    background: #333;
  }
  .sl-tirelabel-desc {
    position: absolute;
    bottom: 2px;
    left: 10px;
    font-size: 11px;
  }
}
