.hpm-App .hpm-gridElement {
  margin: 0 18px 12px 6px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 24px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: flex-start;
  transition: all 150ms ease-in-out 0ms;

  &.mounting {
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      content: "";
      height: 24px;
      width: 24px;
      background-image: url("../../assets/svg/icon_montage-black.svg");
      background-repeat: no-repeat;
      background-color: white;
      background-size: 20px;
      background-position: center;
      z-index: 2;
    }

  }

  &.discounted {
    .hpm-teaserImage:after {
      position: absolute;
      width: 24px;
      height: 24px;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 21px;
      content: '';
      background-color: rgba(225, 050, 100, 1.00);
      color: rgba(255, 255, 255, 1.00);
      background-image: url("../../assets/svg/icon_sale-white.svg");
    }
  }

  &:hover {
    box-shadow: 0 0 0 0.5em white, 0 0 1em 0 rgba(000, 000, 000, 1.00);
  }

  div {
    width: 100%;
  }

  .hpm-textWrapper {
    margin: 8px 4px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;

    .hpm-description {
      position: relative;
      font-style: normal;
      font-weight: normal;
      color: rgba(000, 000, 000, 1.00);
      text-decoration: none;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      font-size: 12.6px;
      margin-top: 0.3em;
      font-family: var(--font100), Arial, sans-serif;
    }

    .hpm-title {
      font-style: normal;
      font-weight: normal;
      color: rgba(033, 040, 050, 1.00);
      text-decoration: none;
      overflow: hidden;
      word-wrap: break-word;
      width: 100%;
      font-size: 14px;
      font-family: var(--font200), Arial, sans-serif;
      max-height: var(--maxTitleHeight);
      line-height: var(--titleLineHeight);
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      -ms-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }
  }

  .hpm-teaserImageWrapper {
    width: 100%;
    overflow: hidden;
    min-height: 120px;
  }

  .hpm-teaserImage {
    min-height: 110px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: var(--productImageSize, auto 100%);
    background-position: center;
    transition: background-size 0.5s;
    position: relative;
    aspect-ratio: var(--productImageAspectRatio, 14/10);

    &:before {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      box-shadow: inset 0 0 3.6em rgba(0, 0, 0, .3);
    }

    .hpm-sale {
      width: 100%;
      padding: 0 0 0 6px;
      height: 23px;
      color: rgba(225, 50, 100, 1.00);
      font-size: 14px;
      background-size: 80%;
      background-position: 51% 52%;
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0;
      left: 0;
      font-family: var(--font200), Arial, sans-serif;
    }

    .hpm-assembly {
      width: 30px;
      height: 30px;
      background-image: url(../../assets/svg/icon_montage-black.svg);
      background-size: 80%;
      background-position: 51% 52%;
      background-repeat: no-repeat;
      position: absolute;
      opacity: 0.80;
      background-color: #fff;
      top: 0;
      right: 0;
    }
  }

  .hpm-gridPriceBox {
    width: 100%;
    bottom: 0;
    color: rgba(033, 040, 050, 1.00);
    font-size: 14px;

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 5px;

    .hpm-retailPrice {
      text-decoration: line-through;
      float: left;
      font-size: 0.90em;
      bottom: 2px;
      display: flex;
      flex: 1 1 10%;
      flex-flow: row wrap;
      align-content: flex-end;
      justify-content: flex-start;
      font-family: var(--font100), Arial, sans-serif;
    }

    .hpm-price {
      float: right;
      font-size: 1.05em;
      display: flex;
      justify-content: flex-end;
      flex: 1 1 10%;
      flex-flow: row wrap;
      align-content: flex-end;
      font-family: var(--font200), Arial, sans-serif;
      text-align: right;
    }
  }

  .unit-price {
    font-size: 10px;
    font-family: var(--font100), Arial, sans-serif;
    margin-left: 99px;
    text-align: right;
  }

  .gridLocationSelect {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    font-size: 0.85rem;
    white-space: nowrap;
    transition: transform 0.2s ease, box-shadow 0.2s ease, padding 0.2s ease;

    &:hover {
      font-size: 0.8rem;
      cursor: pointer;
    }
    &:hover:before {
      line-height: 1.1;
      content: "»  ";
      padding-right: 2px;
      color: var(--secondaryColor);
    }
  }
}
