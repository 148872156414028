.hpm-App {
  .swimLane {
    width: 100%;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 10px;
  }

  .embla {
    overflow: hidden;
    position: relative;

    * {
      transition: unset;
    }
  }

  .embla__container {
    display: flex;

    .hpm-gridElement {
      margin: 10px;
      position: relative;
      flex: 0 0 175px;
    }

    .hpm-gridCategory-btn {
      text-align: center;
      align-content: center;
    }
  }

  .carouselBtn {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    opacity: .84;
    border-radius: 100%;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 24px;
    background-color: #fff;

    background-image: url("../assets/svg/baseline-keyboard_arrow_left-24px.svg");
    background-position: center left 12px;
    left: -20px;
    z-index: 3;

    + .carouselBtn {
      background-image: url("../assets/svg/baseline-keyboard_arrow_right-24px.svg");
      background-position: center right 12px;
      left: unset;
      right: -20px;
      z-index: 3;
    }
  }
}

