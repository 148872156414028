#app-root .hpm-questionProduct,
#itt-sl-hpm .hpm-questionProduct {

  margin-left: 20px;

  .hpm-productImage {
    width: 85%;

    img {
      max-width: 100%;
      max-height: 500px;
      pointer-events: none;
    }
  }

  .hpm-productDescription {
    width: 85%;
    text-align: justify;
    padding: 0 6px 12px 0;

    h3 {
      margin-top: 16px;
      padding: 0 6px 12px 0;
    }

  }

  .hpm-productPrice {
    max-width: 90%;

    .hpm-priceLabel {
      font-family: var(--font100);
    }

  .hpm-priceText {
    font-family: var(--font200);
  }

    .unit-price {
      font-family: var(--font100);
      font-size: 10px;
    }
  }



  &.hpm-chat-image {
    background-color: silver;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 61.8% auto;
    background-image: url("../../../assets/svg/baseline-chat_bubble_outline-24px.svg");
    margin-right: 30px;
  }


}
