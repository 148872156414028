.hpm-App {
  button {
    transition: initial;
    -webkit-appearance: initial;
    font-size: initial;
    font-family: inherit;
    line-height: initial;
    letter-spacing: initial;
    text-transform: initial;
    display: initial;
    cursor: initial;
    -webkit-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
    vertical-align: initial;
    background-color: initial;
    margin: initial;
    outline: initial;
    text-align: initial;
    height: initial;
    background-image: initial;
  }

  a {
    color: initial;
    border-bottom: initial;
    letter-spacing: initial;
    transition: initial;
    background: initial;
    text-transform: inherit;
    font-weight: inherit;
  }

  button, input, optgroup, select, textarea {
    margin: initial;
  }

  label, table, tr, td, div,
  p, form, button, pre, blockquote,
  ol, ul, li, dl, dt, dd, a, small,
  span, img, tbody, fieldset, select, option, textarea, input {
    &.ball-beat {
      > div {
        border-radius: 100%;
        text-align: center;
      }
    }

    font-size: unset;
    border-radius: unset;
    border: unset;
    box-sizing: unset;
    color: black;
    padding: unset;
    text-align: initial;
  }
}
