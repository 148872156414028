#app-root .hpm-searchPage,
#itt-sl-hpm .hpm-searchPage {
  overflow-x: hidden;
  background-color: var(--moduleBackground);
  margin: 20px var(--moduleBorderMargin);
  width: calc(100% - var(--moduleBorderMargin) - var(--moduleBorderMargin));

  .hpm-categoryContainer .hpm-gridList {
	width: 100%;
  }

  .hpm-categoryContainer {
	> div {
	  &.hpm-gridScrollWrapperContainer {
		margin: 0;

		> .hpm-gridScrollWrapper {
		  margin: 0 0;
		}
	  }
	}
  }

  .hpm-searchTitle {
	&.hpm-searchTitle {
	  text-align: center;
	  font-size: 18px;
	  padding: 20px 0 0;
	  box-sizing: border-box;
	  background-color: var(--moduleBackground);
	  margin-top: 10px;
	  margin-bottom: 36px;
	  font-family: var(--font100), Arial, sans-serif;
	}
  }

  .hpm-categoryTitle {
	font-family: var(--font200), Arial, sans-serif;
  }

	.show-more-products {
		background-color: var(--primaryColor);
		border: 1px solid var(--primaryColor);
		color: var(--primaryTextColor);
		padding: 15px 32px;
		text-align: center;
		text-decoration: none;
		font-size: 16px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 20px auto;
	}

	.total-items {
		padding: 15px 32px;
		text-align: center;
		font-size: 16px;
		margin: 20px auto;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		position: relative;
		font-family: var(--font100);
		text-decoration: underline;
	}

}



