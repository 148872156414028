.page-middle {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
  padding-bottom: 70px;
}

#itt-dealer-finder {
  button, input {
    border: unset;
  }

  a {
    color: #000;
  }
}
