.slCaptcha {
  margin-top: 12px;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  flex: 0 0 50px;

  .captchaImage {
    flex: 0 0 100px !important;

    img {
      width: 96px;
      border: 2px solid rgba(0, 0, 0, 0.15);
    }
  }

  .captchaInput {
    width: 100%;
    flex: 0 0 150px !important;
    border: 0 solid transparent;
    margin-right: 20px;
  }
}