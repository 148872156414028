.hpm-App .NscDealerSearch {
  width: 100%;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, .15);
  float: right;
  box-sizing: border-box;

  > div {
    width: 100%;
  }

  span {
    float: right;
    font-family: var(--font200), Arial, sans-serif;
    font-style: normal;
    font-weight: 100;
    display: block;
    font-size: 1.2em;
  }

  div.hpm-nscPriceBox {
       float: right;
       margin-bottom: 1em;

       + .hpm-dealerCount {
         padding-bottom: 20px;
       }

       .hpm-price {
         font-size: 1.20em;
         font-style: normal;
         font-weight: normal;
         color: rgba(000, 000, 000, 1.00);
         text-align: right;
         font-family: var(--font200), Arial, sans-serif;

         &.hpm-stroke {
           text-decoration: line-through;
           font-size: 0.90em;
           font-family: var(--font100), Arial, sans-serif;
         }
       }

       .hpm-retailPrice {
         font-size: 1.20em;
         font-style: normal;
         font-weight: normal;
         text-align: right;
         font-family: var(--font200), Arial, sans-serif;
       }


       .hpm-info {
         font-size: 14px;
         font-style: normal;
         font-weight: normal;
         text-align: right;
         font-family: var(--font100), Arial, sans-serif;

         > span {
           display: block;
         }
       }
     }

  div.hpm-dealerCount {
    padding-bottom: 40px;
  }

  div.hpm-actions {
    display: flex;
    margin: 0 0 .6em;

    .hpm-cartButton {
      flex: 1 1 80%;
      display: block;
      margin: 10px 0 0;
      font-style: normal;
      font-weight: 400;
      line-height: 42px;
      text-transform: uppercase;
      text-align: center;
      overflow: hidden;
      box-sizing: unset;
      padding-bottom: 0;
      padding-top: 0;
      background-color: var(--primaryColor);
      color: var(--primaryTextColor);
      transition: all .2s ease-in-out;
      font-family: var(--font200), Arial, sans-serif;
    }
  }

  div.hpm-priceDisclaimer {
    font-size: 0.84em;
    color: rgba(000, 000, 000, 1.00);
    font-family: var(--font100), Arial, sans-serif;
  }


}
