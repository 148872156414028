@import "./ScrollbarStyling";

.gridList {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

#itt-sl-hpm,
#app-root {
  font-size: 14px;
  font-family: var(--font100), Arial, courier, sans-serif;
  overflow: hidden;
  line-height: initial;
  width: 100%;

  .hpm-loadingSpinner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  table, tr, td {
    border: 0;
    color: black;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.hpm-center {
  text-align: center !important;
}

.hpm-onlyPrint {
  display: none !important;
}

@media only print {
  #root {
    position: relative;
    padding: 5em;
    width: calc(100% - 10em);
  }

  .hpm-onlyPrint {
    display: block !important;
  }
  .hpm-noPrint {
    display: none !important;
    max-height: 0;
    height: 0;
  }
}

.colorPicker {
  background-color: var(--primaryColor) !important;
  display: none !important;
}