.hpm-App {
  div.hpm-showCase-categories-small,
  div.hpm-showCase-categories {

    &.hpm-showCase-categories-small {
      justify-content: flex-start;
      align-content: flex-start;
      font-family: var(--font100), Arial, sans-serif;
    }

    .hpm-saleTile {
      > span:first-child {
        background-image: var(--carImage);
      }
    }

    position: relative;
    display: flex;
    justify-content: center;
    padding: 0.5em;
    margin-bottom: 2.1em;
    flex-flow: row wrap;
    align-content: stretch;

    a.hpm-category {
      flex: 0;
      flex-basis: calc(33.3% - 1em);
      min-width: 300px;
      cursor: pointer;
      text-decoration: none;
      position: relative;
      transition: all 150ms ease-in-out 0ms;
      margin: 0.5em;
      opacity: 1;
      letter-spacing: initial;
      font-family: var(--font100), Arial, sans-serif;

      &.small {
        flex-basis: unset;
        padding: 0.33em;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.7em;
        min-width: unset;
        box-shadow: 0 0 0 0.3em white, 0 0 0.5em 0 rgba(0, 0, 0, 1.00);

        > span.hpm-showCaseElement {
          aspect-ratio: unset;
        }
      }


      &:hover {
        box-shadow: 0 0 0 0.5em white, 0 0 1em 0 rgba(0, 0, 0, 1.00);
        background-color: rgba(255, 255, 255, 1.00);
      }

      span {
        display: block;
      }

      > span:nth-child(1) {
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        aspect-ratio: 16/9;

        > img {
          width: 56.00%;
          height: auto;
          display: block;
        }
      }

      > span:nth-child(2) {
        max-width: 68.00%;
        position: relative;
        padding-top: 1.2em;
        padding-bottom: 1.20em;

        h2 {
          font-size: 1.2em;
          line-height: 1;
          text-align: left;
          letter-spacing: initial;
          opacity: 1;
          font-weight: initial;
          margin: 0;
          font-family: var(--font100), Arial, sans-serif;
          box-shadow: none!important;
          padding: initial;

          + span {
            margin-top: 0.50em;
            font-family: var(--font200), Arial, sans-serif;
          }
        }
      }

      .hpm-semibtn {
        padding: 0.75em 1.00em;
        position: absolute;
        right: 0;
        bottom: 0.6em;
        font-size: 0.9em;
        text-align: center;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.12);
        font-family: var(--font200);
      }
    }
  }
}
