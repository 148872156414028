#app-root .hpm-modal,
#itt-sl-hpm .hpm-modal {
  transition: max-height 150ms ease-in-out 0ms;
  height: calc(100% - 32px);
  max-height: 0;
  width: 100%;
  z-index: 10;
  overflow-y: auto;
  background-color: #fff;
  margin-top: 0;
  position: absolute;

  &.hpm-transparentModal {
    top: 1px;
    left: 0;
    width: 100%;
    height: 10000px;
    z-index: 9;
    margin: 0;
    padding: 0;
    background: rgba(255, 255, 255, 0.6);
    overflow-x: hidden;
  }

  &.hpm-small {
    max-width: 400px;
    border-width: 0 1px 0;
    border-color: rgba(000, 000, 000, 0.15);
    border-style: solid;
    height: 570px;
    overflow: hidden;

  }

  .hpm-modalNavigation {
    height: 36px;
    width: 100%;
    background-color: #fff;
    font-family: var(--font100), Arial, sans-serif;

    .hpm-modalClose {
      float: right;
      display: inline-block;
      width: 36px;
      height: 36px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 24px;
      background-image: url("../../assets/svg/baseline-close-24px.svg");
      font-family: var(--font100), Arial, sans-serif;

      &:hover {
        cursor: pointer;
      }
    }

    .hpm-modalCloseText {
      float: right;
      display: inline-block;
      height: 36px;
      padding: 10px 10px 0 0;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

#app-root .hpm-App .hpm-modalContainer,
#itt-sl-hpm .hpm-App .hpm-modalContainer {
  z-index: 4;
}

.hpm-modalContainer {
  position: relative;
}

.hpm-filterContainer {

  &.hpm-filterContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: white;
    margin: 0;
    padding: 0;
    border: 0;
  }

  .hpm-modalNavigation {
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 12px);
    padding: 0 12px 0 0;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: rgba(000, 000, 000, 0.15);

    .hpm-modalBack {
      display: inline-block;
      width: 36px;
      height: 36px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 24px;
      background-image: url("../../assets/svg/baseline-keyboard_arrow_left-24px.svg");

      &:hover {
        cursor: pointer;
      }
    }

    .hpm-modalCloseText {
      display: inline-block;
      float: right;
      height: 36px;
      padding: 14px 10px 0 0;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .hpm-scrollContainer {
    top: 42px;
    width: 100%;
    height: calc(100% - 84px);
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    left: 0;
  }


  .hpm-filterElements {
    width: calc(100% - 24px);
    height: 100%;
    margin: 0 12px;

  }

  .hpm-resetFilter {
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100% - 24px);
    padding: 0 12px;
    justify-content: center;
    height: 40px;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px 0;
    border-color: rgba(000, 000, 000, 0.15);
    color: rgba(033, 040, 050, 1.00);
    background-color: white;
    margin: 0;
    font-size: 14px;
    line-height: 1.2;
    font-family: var(--font100), Arial, sans-serif;

    &:hover {
      background-color: #f6f6f6;
      cursor: pointer;
    }
  }

}




