#app-root .hpm-payment,
#itt-sl-hpm .hpm-payment {
  width: 100.0%;
  max-width: 1000px;
  height: 100.0%;
  margin: 0 auto;

  label {
    font-family: var(--font100), Arial, sans-serif;
  }

  .hint {
    margin-bottom: 24px;
    background-color: hsl(33.3 100% 96.5%);
    border: 1px solid hsl(32.1 97.7% 83.1%);
    padding: 7px;
    font-size: 14px;
    font-family: var(--font100), Arial, sans-serif;
    line-height: 1.5;
  }

  .hpm-adressContainer {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    font-family: var(--font100), Arial, sans-serif;

    .hpm-mainContentBox {
      flex: 2 1 400px;
      position: relative;
      margin: 12px 0 24px 24px;
      border-right: 1px solid #d9d9d9;
      padding: 0 24px 0 0;
      font-family: var(--font100), Arial, sans-serif;

      .hpm-loadingSpinner {
        max-height: 115px;
      }

      .hpm-paymentFacility {
        font-family: var(--font100), Arial, sans-serif;
        margin-top: 0;

        .spinnerBox {
          &:nth-child(2) {
            display: none;
          }
        }

        button[disabled] {
          opacity: 0.4;
        }

        .hpm-payOnPickupButton, .hpm-prepaidButton {
          flex: 1 1 80%;
          display: block;
          font-style: normal;
          font-weight: normal;
          line-height: 36px;
          height: 36px;
          text-transform: uppercase;
          text-align: center;
          overflow: hidden;
          box-sizing: unset;
          padding-bottom: 0;
          padding-top: 0;
          width: 100%;
          min-width: 150px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          font-family: var(--font100), Arial, sans-serif;
          color: black;
        }

        &.selected .hpm-prepaidButton {
          background-color: var(--primaryColor);
          color: var(--primaryTextColor);
          border: 1px solid var(--primaryColor);
          font-family: var(--font200), Arial, sans-serif;
        }
      }

      .hpm-adressTitle {
        width: 100.0%;
        margin-bottom: 42px;
        font-size: 1.05em;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-content: flex-end;
        align-items: flex-end;
        font-style: normal;
        font-weight: bold;
        color: #212832;
        font-family: var(--font100), Arial, sans-serif;
      }

    }

    .hpm-dataSummaryBox {
      flex: 1 1 200px;
      margin: 12px 24px;
      position: relative;
    }

  }


  .hpm-menu {
    width: 100%;

    &.hpm-payment {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 16px;
      grid-row-gap: 16px;


      > div {
        flex: 1 0 150px;
      }
    }

  }

  @media (max-width: 768px) {
    .hpm-menu.hpm-payment {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
  }


}

