#app-root .hpm-articleInfoBox,
#itt-sl-hpm .hpm-articleInfoBox {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 500px;
  background-color: rgba(255, 255, 255, 1.00);
  font-family: var(--font100), Arial, sans-serif;
  margin-top: 15px;

  @media (max-width: 599px) {
    position: relative;
    height: auto;
    overflow: auto;
  }

  .hpm-navigation {
    list-style: none;
    padding: 0;
    width: 100%;
    float: left;
    display: none;
    margin: 0;

    li {
      list-style-type: none;
      float: left;
      padding: 15px 0 5px;
      margin: 0 15px 10px;
      font-family: var(--font100), Arial, sans-serif;
      max-width: unset;
      margin-top: unset;
      margin-bottom: 6px;


      &:hover {
        cursor: pointer;
      }

      &.hpm-selected {
        border-bottom: 4px solid var(--secondaryColor);
      }
    }
  }

  .hpm-tab {
    width: 100%;
    transition: margin 1s;
    left: -200%;
    margin: 0 0 0 0;
    position: absolute;
    top: 50px;
    overflow: hidden;

    &.hpm-open {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }

    &.hpm-slideRight,
    &.hpm-slideLeft,
    &.hpm-slideRightAfter,
    &.hpm-slideLeftAfter {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    @media (max-width: 599px) {
      &.hpm-slideRight,
      &.hpm-slideLeft,
      &.hpm-slideRightAfter,
      &.hpm-slideLeftAfter {
        visibility: visible !important;
        opacity: 1 !important;
      }
    }

    @media (max-width: 599px) {
      position: relative;
      top: auto;
      margin: 0 0 0 0 !important;
    }

    .hpm-topic {
      display: block;
      padding: 12px;
      border-top: 1px solid rgba(000, 000, 000, 0.15);
      background-repeat: no-repeat;
      background-position: center right;
      background-image: url("../../assets/svg/baseline-keyboard_arrow_right-24px.svg");
      font-family: var(--font200), Arial, sans-serif;
    }
  }

  .hpm-tab:last-child {
    border-bottom: 1px solid rgba(000, 000, 000, 0.15);
  }

  .hpm-tab.hpm-open .hpm-topic {
    background-image: url("../../assets/svg/baseline-keyboard_arrow_down-24px.svg");
  }

  .hpm-tab.hpm-slideRight {
    margin: 0 0 0 -100%;
    left: 0;
  }

  .hpm-tab.hpm-slideLeft {
    margin: 0 0 0 100%;
    left: 0;
  }

  .hpm-tab.hpm-open {
    margin: 0 0 0 0;
    left: 0;
    @media (max-width: 599px) {
      .hpm-tabcontent {
        display: inline-block;
      }
    }
  }

  .hpm-tab.hpm-slideRightAfter {
    left: 200%;

    @media (max-width: 599px) {
      left: 0 !important;
    }
  }

  .hpm-tab.hpm-slideLeftAfter {
    left: -200%;
  }

  .hpm-tabcontent {
    display: inline-block;
    padding: 0 15px 15px;
    width: 100%;
    box-sizing: border-box;
    transition: margin 1s;

    li, ul {
      max-width: unset;
      margin-top: unset;
      list-style: disc;
      margin-left: 15px;
    }

    @media (max-width: 599px) {
      display: none;
    }

    &.hpm-vehicle {
      ul {
        list-style-type: none;
        padding: 0;
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;

        li {
          margin: 12px;
          vertical-align: top;
          font-family: var(--font100), Arial, sans-serif;
          list-style-type: none;
          @media (max-width: 599px) {
           display: flex;
            flex-direction: row;
          }
        }

        li:before {
          content: "–";
          margin-right: 10px;
          height: 100%;
          display: block;
          float: left;
        }

        li.hpm-emptyVehicle {
          height: 0;
          margin: 0;
          padding: 0 15px;
        }

        li.hpm-emptyVehicle:before {
          content: "";
        }
      }
    }

    &.hpm-data {
      ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-flow: row wrap;

        li {
          margin: 12px;
          flex: 1 1 30%;
          vertical-align: top;
          font-family: var(--font100), Arial, sans-serif;

          @media (max-width: 599px) {
            flex: 1 1 100%;
          }

          span {
            width: 50%;
            display: inline-block;
            float: left;
            color: rgba(033, 040, 050, 1.00);
            opacity: 0.72;

            @media (max-width: 599px) {
              width: 100%;
            }

            & + span {
              width: 50%;
              color: rgba(033, 040, 050, 1.00);
              opacity: 1;

              @media (max-width: 599px) {
                width: 100%;
              }
            }
          }
        }

        li.hpm-emptyData {
          height: 0;
          margin-top: 0;
          margin-bottom: 0;
          flex: 1 1 12.00em;
          padding: 0 15px;
          font-family: var(--font100), Arial, sans-serif;
        }
      }
    }

    &.hpm-file {
      ul {
        list-style-image: url("../../assets/svg/baseline-description-24px.svg");
        padding: 0;
        display: flex;
        flex-flow: row wrap;
        padding-left: 25px;

        li {
          margin: 12px;
          flex: 1 1 30%;
          vertical-align: top;
          font-family: var(--font100), Arial, sans-serif;

          @media (max-width: 599px) {
            flex: 1 1 100%;
          }

          a {
            display: inline-block;
            margin-top: 4px;
            float: left;
            text-decoration: none;
            color: rgba(033, 040, 050, 1.00);

            &:hover {
              text-decoration: underline;
            }
          }
        }

        li.hpm-emptyFile {
          height: 0;
          margin: 0;
          list-style: none;
          padding: 0 15px;
        }
      }
    }
  }
}

@media (min-width: 600px) {
  #app-root .hpm-articleInfoBox,
  #itt-sl-hpm .hpm-articleInfoBox {
    background-color: #f3f3f3;
    font-family: var(--font100), Arial, sans-serif;

    .hpm-tabcontent {
      &.hpm-data {
        ul {
          li {
            border-top: 1px solid rgba(000, 000, 000, 0.15);
            padding: 12px 0 0 0;
            font-family: var(--font100), Arial, sans-serif;
          }

          li.hpm-emptyData {
            border-top: 0;
          }
        }
      }
    }

    .hpm-tab:last-child {
      border-bottom: 0;
    }

    .hpm-navigation {
      display: block;
    }

    .hpm-tab {
      .hpm-topic {
        display: none;
      }
    }
  }
}

.hpm-tab.hpm-open #desctab {
  h1, h2, h3, h4, h5, h6 {
    text-align: left;
    margin: 10px 0;
    font-family: var(--font200), Arial, "Sans-Serif", serif;
    font-size: 1.6em;
  }

  h1 {
    font-size: 1.6em;
    font-weight: 800;
  }

  h2 {
    font-size: 1.4em;
    font-weight: 700;
  }

  h3 {
    font-size: 1.2em;
    font-weight: 600;
  }

  h4 {
    font-size: 1.1em;
    font-weight: 500;
  }

  h5 {
    font-size: 1em;
    font-weight: 500;
  }

  h6 {
    font-size: 0.9em;
    font-weight: 400;
  }
  p {
    font-family: var(--font100), Arial, "Sans-Serif", serif;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
    line-height: 150%;
  }

  ul, ol {
    margin: 24px;
    margin-top: 0;
    padding-left: 20px;
    list-style-position: outside;
    line-height: 25px;
  }

  ul {
    list-style-type: disc;
  }

  ol li {
    list-style-type: decimal;
  }

  b, strong {
    font-weight: 600;
  }

  i, em {
    font-style: italic;
    font-family: var(--font100), Arial, sans-serif;
  }

  small {
    font-size: 0.8em;
    line-height: 1.2;
    color: rgba(33, 40, 50, 0.8);
    font-family: var(--font100), Arial, sans-serif;
  }

  mark {
    background-color: var(--primaryColor);
    color: var(--primaryTextColor);
  }

  sub {
    font-size: 0.85em;
    line-height: 1;
    vertical-align: baseline;
    color: rgba(33, 40, 50, 0.9);
    font-family: var(--font100), Arial, sans-serif;
  }

  a {
    color: inherit;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: rgba(0, 0, 0, 0.7);
    }
  }

  hr {
    border: none;
    height: 1px;
    background-color: rgba(128, 128, 128, 0.5);
    margin: 20px 0;
  }

  table {
    margin-top: 20px;
    border-collapse: collapse;
    overflow: auto;
    border-spacing: 10px;
    width: 100%;
  }

  tr {
    border-bottom: 1px solid rgba(128, 128, 128, 0.5);
  }

  td{
    max-width: 256px;
    padding: 10px;
  }

  th, td h1{
    font-size: 16px;
    font-weight: 600;
    border: none;
    padding: 16px;
    text-align: left;
  }
}
