#itt-sl-hpm,
#app-root,
.ReactModalPortal {

  * {

    // working for Safari and Chrome
    ::-webkit-scrollbar {
      width: 9px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-color: rgba(155, 155, 155, 0.5);
      border-radius: 20px;
      border: transparent;
    }

    // working in Firefox
    scrollbar-width: thin;

  }

  .gm-style-iw-d {
    overflow: hidden !important;
  }

}
