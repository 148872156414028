#app-root .hpm-button,
#itt-sl-hpm .hpm-button {
  display: flex;
  align-items: center;
  justify-content: center;

  &#hpm-search {
	background-color: white;
    border-right: 1px solid rgba(000,000,000,0.15);
  }

}


