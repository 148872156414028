#app-root .hpm-dealerList,
#itt-sl-hpm .hpm-dealerList {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  color: #fff;
  position: relative;
  z-index: 3;
  overflow: visible;

  > div {
    position: relative;
    color: #212832;
  }

  &.enter, &.enter-active {
    max-height: 0;
  }

  &.enter-done {
    max-height: 2000px;
    transition: max-height 500ms linear;
  }

  &.exit {
    max-height: 2000px;
  }

  &.exit-active {
    max-height: 0;
    transition: max-height 500ms linear;
  }

  .hpm-close-dealerList {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 24px;
    background-image: url("../../../assets/svg/baseline-close-24px.svg");
    background-color: unset;
    margin-right: unset;

    &:hover {
      cursor: pointer;
    }
  }
}
