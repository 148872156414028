.hpm-spsCouponCodeBox {
  margin: 0 0 15px;

  &.disabled {
    filter: grayscale(1);
    input + * {
      cursor: not-allowed;
    }
  }

    input {
      display: inline;
      -moz-appearance: checkbox;
      visibility: initial;
      position: initial;
      opacity: initial;
      pointer-events: initial;
    }

    .hpm-title {
      font-style: normal;
      font-weight: bold;
      color: #212832;
      margin: 0 0 10px;
      display: block;
    }

    .hpm_couponWrapper {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      margin-top: 6px;

      .hpm-coupon-code {
        flex-grow: 1;
        min-width: 100px;
        width: 100%;
        padding: 6px;
        border: 0;
        font-family: Arial, sans-serif;
      }
    }

  .loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #bbf7d0;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

    .accountPointsInfo {
      width: calc(100% - 26px);
      display: flex;
      flex-direction: column;
      border: 1px solid #f6f6f6;
      background-color: #f6f6f6;
      font-size: 0.74rem;
      margin-top: 7px;
      padding: 12px 12px 12px 0;
      font-family: var(--font100), Arial, sans-serif;
      position: relative;
      line-height: 1.4;

    .switch-checkbox {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .coupon-text {
      background: #bbf7d0;
      font-family: var(--font100), Arial, Sans-Serif;
      padding: 8px;
      color: #052e16;
      font-size:14px;
      margin-left: 52px;
      width: 100%;
    }

    .useCouponText {
      background-color: #e4e4e7;
      padding: 8px;
      margin: 4px 0 0 53px;

      span {
        display: block;
      }
    }
  }
}

.switch {
  position: absolute;
  width: 45px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sps-checkbox {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
  margin: 0 6px 0 0;
}

.sps-checkbox:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  bottom: 0;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .sps-checkbox {
  background-color: #16a34a;
}

input:checked + .sps-checkbox:before {
  transform: translateX(20px);
}

.sps-checkbox.round {
  border-radius: 34px;
}

.sps-checkbox.round:before {
  border-radius: 50%;
}

.no-pointer {
  cursor: default;
}
