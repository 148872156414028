#hpm-toastContainer {

  .Toastify__toast-body {
    flex: 1 1 auto;
    margin: auto 0;
    padding: 6px;
  }

  .toastBody {
    background: white;
    border: 1px solid rgba(000,000,000,0.15) ;
  }

  .toastText {
    background: white;
    color: black;
    font-family: var(--font100);
  };

  .toastProgress {
    background: var(--secondaryColor, var(--primaryTextColor));
  }

  .hpm-onlyPrint {
    display: none !important;
  }

  @media only print {
    &#root {
      position: relative;
      padding: 5em;
      width: calc(100% - 10em);
    }

    .hpm-onlyPrint {
      display: block !important;
    }
    .hpm-noPrint {
      display: none !important;
      max-height: 0;
      height: 0;
    }
  }

}
