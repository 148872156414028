$hpm-car-element-width: 160px;
$hpm-car-element-padding: 24px;

#app-root .hpm-App .hpm-carElement,
#itt-sl-hpm .hpm-App .hpm-carElement {
  height: 90px;
  margin: 0;
  flex-flow: column;
  opacity: 1;
  padding: 12px $hpm-car-element-padding;
  display: flex;
  justify-content: flex-end;
  width: $hpm-car-element-width;

  &:hover {
    transform: scale(1.2);
    cursor: pointer;
    font-family: var(--font200), Arial, sans-serif;
  }

  > img {
    margin: 0 auto;
    height: 80%;
    width: auto;
    transition: transform 0.3s;
    z-index: 0;
    object-fit: scale-down;
    max-width: unset !important;
  }

  .hpm-carSeries {
    z-index: 1;
  }

  div {
    text-align: center;
    font-family: var(--font100), Arial, sans-serif;
  }

}
