#app-root .hpm-startPage,
#itt-sl-hpm .hpm-startPage {
  float: left;
  box-sizing: border-box;
  margin: 20px var(--moduleBorderMargin);
  width: calc(100% - var(--moduleBorderMargin) - var(--moduleBorderMargin));
  background-color: var(--moduleBackground);
  overflow-x: hidden;

  .hpm-categoryTitle {
    font-family: var(--font200), Arial, sans-serif;
  }

  .hpm-teaser-title {
    font-family: var(--font100), Arial, sans-serif;

  }

  .hpm-teaser-description {
    font-family: var(--font100), Arial, sans-serif;
  }

  .hpm-categorySubTitle {
    font-family: var(--font200), Arial, sans-serif;
  }

  .hpm-openCategory {
    font-family: var(--font100), Arial, sans-serif;
  }

}
