#app-root .hpm-orderFinished,
#itt-sl-hpm .hpm-orderFinished {
  background-color: var(--moduleBackground, white);
  width: calc(100.0% - 40px);
  margin: 20px auto;
  position: relative;
  font-family: var(--font100), Arial, sans-serif;

  .hpm-cartButton {
	flex: 1 1 80%;
	display: block;
	margin: 30px 0 0 0;
	font-style: normal;
	font-weight: normal;
	line-height: 42px;
	height: 42px;
	text-transform: uppercase;
	text-align: center;
	overflow: hidden;
	box-sizing: unset;
	padding-bottom: 0;
	padding-top: 0;
	font-family: var(--font200), Arial, sans-serif;
	background-color: var(--primaryColor);
	color: var(--primaryTextColor);

	&.back {
	  width: 100%;
	}

  }

  &.hpm-emptyCart {
	min-height: 500px;

	span {
	  margin-bottom: 2.5em;
	  display: block;
	  width: 100.0%;
	  line-height: 1.20;
	  font-style: normal;
	  font-weight: normal;
	  margin-top: 12px;
	}

	> div {
	  text-align: center;
	  white-space: pre-line;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);
	}
  }

	.hpm-orderFinish {
		text-align: center;
	}


}

