#app-root .hpm-priceRange,
#itt-sl-hpm .hpm-priceRange {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 14px;

  td:last-child{
	vertical-align: middle !important;
  }

  &.hpm-center {
	text-align: center;
	padding: 0 6px;
	font-family: var(--font100), Arial, sans-serif;
  }

  .hpm-searchIcon {
	display: inline-block;
	width: 36px;
	height: 36px;
	background-repeat: no-repeat;
	background-position: center;
	text-decoration: none;
	cursor: pointer;
	background-image: url("../../../assets/svg/baseline-search-24px_black.svg");
	background-size: auto 28px;
  }

  .hpm-sliderStyle {
	position: relative;
	width: 85%;
	margin: 10px 11% 0 4%;
	height: 40px;
	border-width: 0;
  }

  .hpm-handleStyle {
	position: absolute;
	margin-left: -10px;
	margin-top: 10px;
	z-index: 2;
	width: 16px;
	height: 16px;
	text-align: center;
	cursor: ew-resize;
	border-radius: 50%;
	background-image: radial-gradient(circle, black 99%, white 1%);
	color: #333;
	border: 3px solid white;
  }

  .hpm-railStyle {
	position: absolute;
	width: 100%;
	height: 8px;
	margin-top: 16px;
	border-radius: 5px;
	background-color: #E8E8E8;
	cursor: pointer;
  }

  .hpm-trackStyle {
	position: absolute;
	height: 8px;
	z-index: 1;
	margin-top: 16px;
	border-radius: 5px;
	cursor: pointer;
	background-color: var(--primaryColor);
  }

  .hpm-ticksStyle {
	.hpm-ticksLine {
	  position: absolute;
	  margin-top: 25px;
	  margin-left: -0.5px;
	  width: 1px;
	  height: 8px;
	  background-color: silver;
	}

	.hpm-ticksValue {
	  position: absolute;
	  margin-top: 33px;
	  font-size: 10px;
	  text-align: center;
	  font-family: var(--font100), Arial, sans-serif;
	}

  }

  tbody tr td {
	height: 36px;
	background-color: unset;
	border: 0;
	padding: unset;
	line-height: unset;
	text-align: unset;
	vertical-align: unset;

	&.hpm-placeholderTd {
	  height: 20px;
	}

	.hpm-currencyInput {
	  position: relative;
	  width: calc(100% - 16px - 2px);
	  height: calc(100% - 2px);
	  padding: 0;
	  border-width: 1px;
	  margin: 0 8px;
	  list-style: none;
	  border-style: solid;
	  border-color: rgba(0, 0, 0, 0.15);
	  text-align: left;

	  .hpm-currencyField {
		font-family: var(--font100), Arial, sans-serif;
	  }

	  div {
		position: absolute;
		display: block;
		transform: translate(0, -50%);
		top: 50%;
		right: 0;
		width: 30px;
		text-align: center;
		padding-left: 5px;
		padding-right: 5px;
		border-left: 1px solid rgba(0, 0, 0, 0.15);
	  }

	  input {
		height: calc(100%);
		width: calc(100% - 41px);
		text-align: center;
		padding: 0;
		border: 0;
		margin: 0;
	  }

	  input[type=number]::-webkit-inner-spin-button,
	  input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		margin: 0;
	  }

	  input[type=number] {
		-moz-appearance: textfield;
	  }
	}

	input[type="checkbox"] {
	  display: none;
	  -moz-appearance: none;
	}

  }
}
