#app-root .hpm-products,
#itt-sl-hpm .hpm-products {
  overflow-x: hidden;
  background-color: var(--moduleBackground);
  margin: 20px var(--moduleBorderMargin);
  width: calc(100% - var(--moduleBorderMargin) - var(--moduleBorderMargin));

  .hpm-categoryContainer {
    width: 100%;
  }
  .hpm-gridList {
    width: 100%;
    overflow: auto;
  }


  .hpm-categoryContainer {
    > div {
      &.hpm-gridScrollWrapperContainer {
        margin: 0;

        > .hpm-gridScrollWrapper {
          margin: 0 0;
        }
      }
    }
  }

  .hpm-categoryTitle {
    font-family: var(--font200), Arial, sans-serif;
  }

  .hpm-searchTitle {
    font-family: var(--font100), Arial, sans-serif;
  }

  .show-more-products {
    background-color: var(--primaryColor);
    border: 1px solid var(--primaryColor);
    color: var(--primaryTextColor);
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
  }

  .total-items {
    padding: 15px 32px;
    text-align: center;
    font-size: 16px;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    font-family: var(--font100);
    text-decoration: underline;
  }
}



