#app-root .hpm-wishlistPage,
#itt-sl-hpm .hpm-wishlistPage {
  font-family: var(--font100), Arial, sans-serif;

  form, textarea {
    font-family: var(--font100), Arial, sans-serif;
  }

  .price-container .price .bookmark-price {
    font-family: var(--font200), Arial, sans-serif;
  }
  .unit-price{
    font-family: var(--font100), Arial, sans-serif;
    font-size: 13px;
  }

  &.hpm-wishlistPage {
    background-color: var(--moduleBackground, white);
    margin: 20px auto;
    position: relative;
    padding: 1.80em 3.00%;
  }

  .hpm-wishlistContentBox {
    width: 100.0%;
    max-width: 1000px;
    margin: 0 auto;

    .hpm-wishlistContent {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-content: flex-start;
      align-items: flex-start;
      position: relative;

      .hpm-wishlistsTitle {
        font-size: 14px;
        border-color: transparent transparent rgb(224, 224, 224) transparent;
        border-width: 0 0 1px 0;
        border-style: solid;
        padding-bottom: 12px;
        text-align: center;
        width: 100%;
        font-family: var(--font100), Arial, sans-serif;
        display: flex;
        justify-content: space-between;

        .hpm-wishlistLogo {
          display: none;
        }

        .hpm-wishlistSymbol {
          margin: 0 auto 0 auto;

          .hpm-beforeWishlist {
            width: 100%;
            text-align: center;
            display: block;

            img {
              width: 66px;
              height: 66px;
              display: unset;
            }
          }
        }

        .hpm-wishlistName {
          display: none;
        }

      }

      .hpm-wishlistItems {
        padding-top: 12px;
        width: 100%;
        display: block;

        .hpm-wishlistLabel {
          display: none;
        }

        .hpm-wishlistItem {
          display: flex;
          text-align: left;
          align-content: center;
          align-items: center;
          padding-top: 12px;

          &:last-of-type {
            margin-bottom: 100px;
          }

          .hpm-itemButtonbox {
            height: 100%;
          }

          .hpm-productItem {
            display: flex;
            text-align: left;
            align-content: center;
            align-items: center;
            padding-top: 12px;
            flex: 1 1 90%;
            font-family: var(--font100), Arial, sans-serif;
            max-width: 90%;

            .hpm-productItemImagebox {
              width: 200px;
              position: relative;
              display: block;
              max-width: 25%;

              img {
                height: auto;
                width: 120px;
                max-width: 100%;
              }
            }

            .hpm-productItemTextbox {
              padding: 12px;
              line-height: 1.3;
              width: 100%;
              position: relative;

              a {
                text-decoration: none;
                color: inherit;
                font-size: larger;
                max-width: 80%;
                font-family: var(--font100), Arial, sans-serif;
              }
            }
          }
        }

        .hpm-delete {
          background-image: url("../../assets/svg/baseline-delete_forever-24px.svg");
          opacity: 0.45;
          width: 36px;
          height: 36px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: auto 24px;
          margin-left: auto;
          display: block;

          &:hover {
            opacity: 1;
            cursor: pointer;
          }
        }
      }
    }
  }

  .hpm-buttonWrapper {
    margin: 30px 0;
    width: 100%;
    display: flex;
    text-align: left;
    align-content: center;
    align-items: center;
    flex-flow: row wrap;

    .hpm-sendButton {
      flex: 4 1 150px;
      margin: 1px;

      button {
        width: 100%;
        font-style: normal;
        font-weight: normal;
        line-height: 42px;
        text-transform: uppercase;
        text-align: center;
        font-family: var(--font200), Arial, sans-serif;
        background-color: var(--primaryColor);
        color: var(--primaryTextColor);
      }

    }

    .hpm-printButton {
      flex: 1 1 120px;
      margin: 1px;

      button {
        width: 100%;
        font-style: normal;
        font-weight: normal;
        line-height: 42px;
        text-transform: uppercase;
        text-align: center;
        font-family: var(--font200), Arial, sans-serif;
        color: var(--primaryTextColor);

        .hpm-printerIconWrapper {
          display: flex;
          justify-content: center;
          align-items: center;

          .hpm-printerIcon {
            mask-size: cover;
            height: 22px;
            width: 22px;
            display: inline-block;
            mask: url("../../assets/svg/printerIcon.svg");
            background: black;
            margin-right: 8px;
          }

          .hpm-printerText {
            font-family: var(--font200), Arial, sans-serif;
            color: black;
          }
        ;


        }

        .leadModuleDisable {
          background-color: var(--primaryColor);
          color: var(--primaryTextColor);
          text-align: center;
        }
      }
    }
  }

  .hpm-contact-send {
    font-family: var(--font200), Arial, sans-serif;
    background-color: var(--primaryColor);
    color: var(--primaryTextColor);
  }

}

.hpm-wishlistFooter {
  display: none;
  //display: flex;
  width: 100%;
  //height: 50px;
  //border-color: rgb(224, 224, 224) transparent transparent transparent;
  //border-width: 1px 0 0 0;
  //border-style: solid;
  padding-top: 12px;
  padding-bottom: 20px;
  position: fixed;
  bottom: 0;
  background-color: #f3f3f3;
  margin: auto;
  left: 0;
  justify-content: space-between;
  align-content: flex-start;


  .left {
    margin: 25px;

    div {
      text-align: start;
    }
  }

  .right {
    margin: 25px;

    div {
      text-align: end;
    }
  }
}


@media print {
  .hpm-wishlistSymbol {
    display: none;
  }

  .hpm-wishlistLabel {
    display: block !important;
  }

  .hpm-wishlistName {
    display: flex !important;
    justify-content: right;
    align-items: center;
    width: 300px
  }

  .hpm-wishlistLogo {
    display: block !important;

    img {
      max-width: 250px;
      max-height: 80px;
    }
  }

  .hpm-wishlistFooter {
    display: flex;
    width: 100%;
    bottom: 0;
    position: fixed;
    border-color: rgb(224, 224, 224) transparent transparent transparent;
    border-width: 1px 0 0 0;
    border-style: solid;
    padding-top: 12px !important;
    padding-bottom: 20px !important;
    background-color: #f3f3f3;
    page-break-after: always;
    justify-content: space-between;
    align-content: flex-start;

    .left {
      margin: 25px;

      div {
        text-align: start;
      }
    }

    .right {
      margin: 25px;

      div {
        text-align: end;
      }
    }
  }

  .hpm-wishlistItems .hpm-wishlistItem:nth-child(8n) {
    page-break-after: always;
    padding-bottom: 50px;
  }
}
