#app-root,
#itt-sl-hpm {

  .hpm-address {
    width: 100.0%;
    max-width: 1000px;
    height: 100.0%;
    margin: 0 auto;

    .hpm-adressContainer {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-content: flex-start;
      align-items: flex-start;
      background-color: unset;
      margin-right: unset;


      .hpm-mainContentBox {
        flex: 2 1 400px;
        position: relative;
        margin: 12px 0 24px 24px;
        border-right: 1px solid #d9d9d9;
        padding: 0 24px 0 0;

        .hpm-adressTitle {
          width: 100.0%;
          margin-bottom: 12px;
          font-size: 1.05em;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          align-content: flex-end;
          align-items: flex-end;
          font-style: normal;
          font-weight: bold;
          color: #212832;
          font-family: var(--font100), Arial, sans-serif;
        }

        .formWrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;

          @media (min-width: 505px) {
            div {
              flex-grow: 0;
            }
          }
        }

        .hpm-error {
          color: red;

          + input, + select {
            border: 1px solid red;
            display: initial;
            opacity: initial;
            position: initial;
          }
        }

        .hpm-requiredFootnote {
          font-size: 0.80em;
          text-align: right;
          font-family: var(--font100), Arial, sans-serif;
        }

        .hpm-zipcodeField {
          flex: 1 1 33%;
        }

        .hpm-lastNameField, .hpm-firstNameField, .hpm-phoneField, .hpm-emailField {
          flex: 1 1 49%;
          min-width: 200px;
        }

        .hpm-cityField {
          flex: 1 1 65%;
          min-width: 266px;
        }

        .hpm-countryCodeField {
          flex: 1 1 100%;
          select {
            background-color: transparent;
            display: initial;
            opacity: initial;
            position: initial;
          }
        }


        fieldset {
          border: 0;
          padding: 0;
        }

        label {
          width: 100%;
          align-items: center;
          display: flex;
          color: rgba(033, 040, 050, 1.00);
          font-size: 0.84em;
          font-family: var(--font100), Arial, sans-serif;
        }

        textarea {
          resize: vertical;
        }

        input, select, textarea {
          width: 100%;
          padding: 0 6px;
          margin: 5px 0 10px;
          height: 40px;
          border-style: solid;
          border-width: 1px;
          border-color: silver;
          box-sizing: border-box;
          font-family: var(--font100), Arial, courier, sans-serif;
          display: initial;
          opacity: initial;
          position: initial;
        }

        .hpm-shippingAddressContainer {
          margin: 7px 0 0 0;
        }

      }

      .hpm-dataSummaryBox {
        flex: 1 1 200px;
        margin: 12px 24px;
        position: relative;
      }
    }


    .hpm-menu {
      width: 100%;

      .hpm-menuElement {
        height: 30px;

        label {
          display: flex;
          align-items: center;
        }

        input[type="checkbox"] {
          visibility: hidden;
        }

        &.hpm-conditions {
          margin: 10px 0 0 0;
          padding: 10px 0 0 0;
          border-top: 1px solid rgba(000, 000, 000, 0.15);

          .hpm-optionText {
            width: calc(100% - 32px);
          }
        }

        &.hpm-payment {
          border-radius: 4px;
          padding: 6px 6px 0;
          border: 1px solid rgba(000, 000, 000, 0.15);
          margin: 0 12px 6px 0;
          flex: 1 1 9.40em;
          width: auto;

          &:empty {
            border: 0;
            height: 0;
          }
        }

        .hpm-optionText {
          float: left;
          line-height: 1;
          margin-left: 8px;
          padding: 4px 0 0 0;
          font-style: normal;
          font-weight: normal;
          font-size: 0.90em;
          font-family: var(--font100), Arial, sans-serif;

          &:hover {
            cursor: pointer;
          }
        }

        .hpm-circle {
          float: left;
          height: 100%;
          aspect-ratio: 1;
          display: block;
          content: "";
          box-shadow: inset 0 0 0 1px rgba(000, 000, 000, 0.15);
          border-radius: 100.0%;
          background-color: white;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 80.0% auto;

          &.hpm-selected {
            background-image: url("../../../assets/svg/baseline-check-24px.svg");
            background-color: black;
          }
        }
      }
    }


  }

}

