* {
  margin: 0;
}

.hpm-App {
  margin: 0;
  overflow: hidden;

  .hpm-loadingSpinner {
    width: 100%;
    min-height: 500px;
    text-align: center;
    clear: both;

    img {
      margin: auto;
      height: 80px;
    }
  }

  table {
    border-collapse: unset;
    margin: unset;
    table-layout: unset;
    width: unset;

    tr {
      background-color: rgba(0, 0, 0, 0);
    }

    tr:first-child {
      td {
        box-shadow: none;
      }
    }
  }

  * {
    outline: none;
    transition: all 150ms ease-in-out 0ms;
  }

  button {
    background-color: transparent;
    border: 0;
    box-shadow: none;

    &:hover {
      cursor: pointer;
    }
  }

  button::after {
    content: none;
  }

}

.hpm-clear {
  clear: both;
}

@import "init";
@import 'grid';
