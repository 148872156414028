#app-root .hpm-filterItem,
#itt-sl-hpm .hpm-filterItem {
  font-size: 14px;
  width: calc(100.0% - 12px);
  padding: 0 6px 0 6px;
  height: 42px;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: rgba(000, 000, 000, 0.15);
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  align-content: center;
  align-items: center;
  hyphens: auto;
  text-decoration: none;
  color: rgba(033, 040, 050, 1.00);
  list-style: none;
  margin: 0;
  transition: all 150ms ease-in-out 0ms;
  text-rendering: optimizeLegibility;

  .hpm-selectDot {
	width: 24px;
	height: 24px;
	border-radius: 100.0%;
	box-shadow: inset 0 0 0 1px rgba(000, 000, 000, 0.15);
  }

  .hpm-checkedSelect {
	background-image: url("../../../assets/svg/baseline-check-24px.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-color: black;
	background-size: 18px;
  }

  .hpm-menuText {
	width: calc(50% - 30px);
	display: block;
	font-weight: bold;
	font-family: var(--font100), Arial, sans-serif;
  }

  .hpm-selectText {
    width: calc(50% - 30px);
    display: block;
    font-weight: bold;
    font-family: var(--font100), Arial, sans-serif;
  }

  .hpm-infoText {
	width: calc(50% - 30px);
	text-align: right;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: unset;
	font-family: var(--font100), Arial, sans-serif;
  }

  &.useLargeLabel {
    .hpm-selectText {
      width: calc(80% - 30px);
    }
    .hpm-infoText {
      width: calc(20% - 30px);
    }
  }

  .hpm-menuArrow {
	background-image: url("../../../assets/svg/baseline-keyboard_arrow_right-24px.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: auto 24px;
	width: 24px;
	height: 24px;
	margin: 0;
	padding: 0;
  }
}

.hpm-filterItem:hover {
  background-color: #f6f6f6;
  cursor: pointer;
}

input[type="checkbox"] {
  display: none;
  -moz-appearance: none;
}
