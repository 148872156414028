#app-root .hpm-footer_navigation,
#itt-sl-hpm .hpm-footer_navigation {
  font-family: var(--font100), Arial, sans-serif;

  &.hpm-footer_navigation {
	height: 42px;
	border: 0 solid rgba(0, 0, 0, 0.15);
	border-top-width: 1px;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-content: center;
	align-items: center;
	text-align: center;
	font-family: var(--font100), Arial, sans-serif;

  }

  button {
	font-size: 0.84em;
	text-transform: uppercase;
	margin: 0 1.20em;
	color: rgba(033, 040, 050, 1.00);
	text-decoration: none;
	font-family: var(--font100), Arial, sans-serif;

	&:hover {
	  text-decoration: underline;
	}
  }
}

.hpm-modalClose {
  position: absolute;
  right: 7px;
  top: 7px;
  width: 36px;
  height: 36px;
  border: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 24px;
  background-image: url("../../assets/svg/baseline-close-24px.svg");
  background-color: rgba(255, 255, 255, 0.6);

  &:hover {
	cursor: pointer;
  }
}

.hpm-modalContent {
  margin: 10px 5px;

  p {
	margin-top: 0;
	margin-bottom: 1em;
	display: block;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0;
	margin-inline-end: 0;
    font-size: 1.1em;
  }

  a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
	cursor: pointer;
  }

  ul {
	display: block;
	list-style-type: disc;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0;
	margin-inline-end: 0;
	padding-inline-start: 40px;
    color: black
  }

  li {
	display: list-item;
	text-align: -webkit-match-parent;
    color: black
  }

  h1, h2, h3, h4, h5, h6 {
	display: block;
	margin-top: 0;
	margin-bottom: .5em;
	font-weight: 500;
	line-height: 1.2;
  }

  h3 {
	font-size: 1.75em;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0;
	margin-inline-end: 0;
	font-weight: bold;
  }

  h2 {
    font-size: 2em;
	margin-block-start: 0.83em;
	margin-block-end: 0.83em;
	margin-inline-start: 0;
	margin-inline-end: 0;
  }
  h4 {
	font-size: 1.5em;
	margin-block-start: 0.7em;
	margin-block-end: 0.7em;
	margin-inline-start: 0;
	margin-inline-end: 0;
	font-weight: bold;
  }

  blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: 5px solid #757575;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }

  strong {
	font-weight: bold;
  }
}
