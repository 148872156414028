#app-root .hpm-subHeader,
#itt-sl-hpm .hpm-subHeader {
  &.hpm-subHeader {
    width: calc(100% - 2px);
    height: 38px;
    border-width: 1px;
    display: flex;
    border-color: rgba(000, 000, 000, 0.15);
    border-style: solid;
    position: relative;
    z-index: 4;
    background-color: #ffffff;
    transition: none;
  }

  &hpm-fixed {
    position: fixed;
    top: 0;
  }

  #hpm-cart,
  #hpm-bookmarks {
    margin-top: -1px;
    position: relative;
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    background-repeat: no-repeat;
    background-size: auto 1.80em;
    background-position: center;
    border: 0 solid rgba(000, 000, 000, 0.15);
    border-left-width: 1px;
    padding: 0;
    border-radius: unset;
    font-family: var(--font100), Arial, sans-serif;

    &#hpm-cart {
      background-image: url("../../assets/svg/baseline-shopping_cart-24px.svg");
    }

    &#hpm-bookmarks {
      background-image: url("../../assets/svg/outline-star_border-24px.svg");
    }

    span {
      position: absolute;
      top: 2px;
      right: 2px;
      width: 17px;
      height: 17px;
      border-radius: 100.0%;
      background-color: var(--primaryColor);
      color: var(--primaryTextColor, #FFF);
      font-style: normal;
      font-weight: normal;
      font-size: 0.72em;
      line-height: 1.70;
      text-align: center;
      font-family: var(--font200), Arial, sans-serif;
    }
  }

  #hpm-filter {
    background-image: url("../../assets/svg/filter-solid.svg");
    position: relative;
    display: inline-block;
    width: 35px;
    height: 100.0%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 1.30em;
    border-width: 0 0 0 0;
    border-color: rgba(000, 000, 000, 0.15);
    border-style: solid;
    font-family: var(--font100), Arial, sans-serif;
  }

  @media screen and (max-width: 375px) {
    #hpm-category {
      background-image: url("../../assets/svg/filter_symbol_web.svg");
      position: relative;
      display: inline-block;
      min-width: 28px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 1.80em;
      border-width: 0 1px 0 0;
      border-color: rgba(000, 000, 000, 0.15);
      border-style: solid;
      border-radius: unset;
    }
    .button-text {
      display: none;
    }
  }

  .button-text {
    margin: inherit;
    border: 0;
    font-family: Arial, sans-serif;
    padding: inherit;
    text-transform: inherit;
  }

  #hpm-category {
    padding: 0 8px;
    white-space: nowrap;
    position: relative;
    display: inline-block;
    width: auto;
    height: 100.0%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 1.80em;
    border-width: 0 1px 0 1px;
    border-color: rgba(0, 0, 0, 0.15);
    border-style: solid;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(033, 040, 050, 1.00);
    font-family: var(--font100), Arial, sans-serif;
  }

  #hpm-historyback {
    background-image: url("../../assets/svg/baseline-keyboard_arrow_left-24px.svg");
    position: relative;
    display: inline-block;
    margin-top: -1px;
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 1.80em;
    border-width: 0 1px 0 0;
    border-color: rgba(000, 000, 000, 0.15);
    border-style: solid;
    border-radius: unset;
  }

  #hpm-homeButton {
    background-image: url("../../assets/svg/Home_web.svg");
    position: relative;
    display: inline-block;
    margin-top: -1px;
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 1.80em;
    border-width: 0 1px 0 0;
    border-color: rgba(000, 000, 000, 0.15);
    border-style: solid;
    border-radius: unset;
  }

  &.hpm-showSort {
    #hpm-filter {
      display: none;
    }
  }

  &.hpm-showBack {
    #hpm-historyback {
      display: none;
    }
  }

  &.hpm-showBack {
    #hpm-homeButton {
      display: none;
    }
  }
}
