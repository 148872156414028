@import "./InnerImageZoom";

[data-rmiz-btn-open],
[data-rmiz-btn-close] {
  background-color: transparent !important
}

div[data-rmiz-modal-content="true"] {
  transform: scale(1) translate(-50%, -50%) !important;
  left: 50% !important;
  top: 50% !important;
  width: unset !important;

  img {
    margin: 0 !important;
    transform: scale(1.5);
  }
}

.hpm-App .hpm-gallery {

  .hpm-gallery {
    margin: 12px;
    width: 40%;

    @media (max-width: 613px) {
      width: 100%;
    }
  }

  div[data-name="wrapper"] {
    margin: auto;
  }

  .image {
    max-width: 100%;
    overflow: hidden;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    position: relative;

    figure:first-child {
      margin: auto;
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;

      img {
        width: auto;
        height: auto;
        margin: auto;
        max-height: 100%;
        max-width: 100%;
        display: block;
      }

      button:hover {
        cursor: zoom-in;
      }
    }

    @media (max-width: 599px) {
      width: 100%;
    }

    .galleryImage {
      height: 100%;
      width: 100%;
      display: block;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .galleryVideo  {
      video {
        height: 100%;
        max-width: 100%
      }
    }
}

  .previewImages {
    margin: 12px auto 0;
    text-align: center;

    .videoThumbnail.previewImageWrapper .previewImage {
      &:hover {
        background-size: auto 100%;

        &:after {
          background-color: rgba(255, 255, 255, 0.6);
        }
      }

      &:after {
        transition: background-color ease-in-out 0.3s;
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.7);
        background-image: url("../../assets/svg/play_circle_outline-24px.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 50%;
      }
    }

    .previewImageWrapper {
      height: 71px;
      width: 71px;
      overflow: hidden;
      margin: 8px 8px 0 8px;
      justify-content: center;
      display: inline-block;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.15);

      &.videoThumbnail {
        display: inline-flex;

        .previewImage {
          top: unset;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    .previewImageBlur {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: 200%;
      background-position: center;
      transition: background-size 0.5s;
      position: relative;
      filter: blur(25px);
      -webkit-filter: blur(25px);
      top: 0;
    }

    .previewImage {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: center;
      transition: background-size 0.5s;
      position: relative;
      top: -70px;

      &:hover {
        background-size: auto 150%;
      }
    }
  }
}

iiz {
  margin: 0;
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: zoom-in;
}

.iiz__img {
  max-width: 100%;
  height: auto;
  display: block;
  pointer-events: none;
}

.iiz__zoom-img {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  display: block;
}

.iiz__zoom-img--visible {
  visibility: visible;
  opacity: 1;
  cursor: zoom-out;
}

.iiz__zoom-portal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
}

.iiz__btn {
  background: rgba(255, 255, 255, 0.8);
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  padding: 0;
  position: absolute;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
}

.iiz__btn:before {
  content: " ";
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}

.iiz__hint {
  bottom: 10px;
  right: 10px;
  pointer-events: none;
}

.iiz__hint:before {
  content: " ";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.9 19.9'%3E%3Cpath d='M13.9 7.4C13.9 3.8 11 .9 7.4.9S.9 3.8.9 7.4s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5zm5.3 12.5l-6.7-7.2c-1.4 1.3-3.2 2.1-5.1 2.1-4.1 0-7.4-3.3-7.4-7.4S3.3 0 7.4 0s7.4 3.3 7.4 7.4c0 1.7-.6 3.4-1.7 4.7l6.8 7.2-.7.6z' fill='%23000222'/%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
}

.iiz__close {
  top: 10px;
  right: 10px;
  visibility: hidden;
  opacity: 0;
}

.iiz__close--visible {
  visibility: visible;
  opacity: 1;
}

.iiz__close::before {
  content: " ";
  width: 29px;
  height: 29px;
  background-image: linear-gradient(#222, #222), linear-gradient(#222, #222);
  background-size: 100% 1px, 1px 100%;
  transform: rotate(45deg);
}

.hpm-gallery {
  flex: 1 1 320px;
  padding: 0 2.00em;
  z-index: 10;
}

.hpm-gallery > .swiper {
  width: 100.0%;
  overflow: hidden;
}

.hpm-gallery .swiper-gal {
  position: relative;
  aspect-ratio: 1 / 1;
  overflow: hidden !important;
  margin: 1.25rem 0 0;
}

.hpm-gallery .swiper-gal .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 99%;
  height: 92%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: zoom-in;
}

.hpm-gallery-image {
  width: 90%;
  height: 90%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 0 auto !important;
}

.hpm-gallery .swiper-slide > .img {
  padding: 0 var(--marginfix);
}

.swiper {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 297px;
  margin-left: 8px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
}

.hpm-gallery .swiper-gal .video-slide {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  max-height: 90%;
  transform: translate(-50%, -50%);
  object-fit: contain;
}

.hpm-gallery-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 9998;
  overflow: hidden;
}

.hpm-gallery-modal-content {
  position: initial;
  width: 100%;
  max-width: 80%;
  height: 100%;
  max-height: 80%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.hpm-modal-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper-container.swiper-modal {
  width: 100%;
  height: 100%;
}

.hpm-gallery-modal-close {
  position: absolute;
  top: 100px;
  right: 55px;
  cursor: pointer;
  font-size: 30px;
  color: black;
  width: 30px;
  height: 30px;
  background-image: url("../../assets/svg/close.svg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 480px) {
  .hpm-gallery-modal-close {
    top: 70px;
    right: 30px;
    width: 25px;
    height: 25px;
    font-size: 25px;
  }
}

.swiper-button-prev-modal {
  left: 50px !important;
}

.swiper-button-next-modal {
  right: 50px !important;
}

.hpm-modal-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  object-fit: contain;
  transform: translate(-50%, -50%) scale(1.4);
}

@media only screen and (max-width: 768px) {
  .hpm-modal-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .hpm-modal-video {
    transform: translate(-50%, -50%) scale(1);
  }

  .hpm-gallery-modal-content {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .hpm-gallery-modal-content {
    width: 100%;
    height: 100%;
  }

  .hpm-modal-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .hpm-modal-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%) scale(1);
  }
}
