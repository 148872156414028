.page-middle {
  display: flex;
  flex-flow: row wrap;
  margin: auto;
}

.GeoLocator.GeoLocator {
  display: flex;
  flex-flow: row wrap;
  min-height: 400px;
  width: calc(100.0%);
  height: 100.0%;
  margin: 0;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
  padding-bottom: 0;
  font-family: var(--font100, sans-serif);

  &.info {
    justify-content: center;
    align-items: center;
    align-content: center;
    min-height: 200px;
    max-height: 400px;

    div {
      text-align: center;
    }
  }

  &.Map {
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 700px;
    flex: 10 1 320px;
    margin: 0.50em 0.50em 1em;
    padding: 0;
    width: 350px;

    * {
      transition: initial;
      z-index: initial;
    }

    > div {
      height: 100%;
      width: 100%;
    }

    + .List {
      height: 100%;
      margin: 0.50em 0.50em 1em;
      padding: 0;
      display: inline-block;
    }
  }

  .LocationIcon {
    background-image: url("../../assets/svg/geolocation.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100px;
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
    flex-basis: 100%;
  }

  input {
    background-color: rgba(255, 255, 255, 1.00);
    color: rgba(034, 034, 034, 1.00);
    font-size: 0.90em;
    box-shadow: inset 0 0 0 1px rgba(000, 000, 000, 0.12);
    height: 2.50em;
    padding: 0 1.00em;
    display: block;
    width: calc(100.0% - 2em);
    margin-right: -1px;
  }

  &.control {
    label {
      display: inline-block;
      width: 2.50em;
      height: 2.2em;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 2.00em;
      color: var(--primaryTextColor, white);
      background-color: var(--primaryColor, cornflowerblue);
      background-image: url("../../assets/svg/baseline-search-24px.svg");
      cursor: pointer;
    }

    .geolocatorLegend {
      flex: 1 1 50%;
      position: relative;
      display: flex;
      flex-flow: row wrap;
      padding: 0 1.5em;
      justify-content: center;

      @media (max-width: 600px) {
        .indicator.shop.with-shop {
          margin-right: 153px;
          margin-top: 5px;
        }
      }

      @media (max-width: 600px) {
        .indicator.shop.with-shop-preview {
          margin-right: 165px !important;
          margin-top: 5px !important;
        }
      }

      @media (max-width: 1054px) {
        padding-bottom: 10px;
      }

      .indicator {
        padding: 0 0.5em 0 0.5em;
        display: flex;
        align-items: center;
        font-size: 0.84em;
        line-height: 1.2;
        white-space: pre-line;
        cursor: pointer;
        max-width: 250px;

        + .indicator {
          margin-left: 0.9em;
        }

        &:before {
          box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.12);
          background-color: rgba(255, 255, 255, 1.00);
          content: '';
          display: inline-block;
          width: 3em;
          min-width: 3em;
          height: 3em;
          margin-right: 0.50em;
          background-repeat: no-repeat;
          background-position: center;
          background-size: auto 1.50em;
        }

        &.location:before {
          background-image: url("../../assets/svg/location_on.svg");
        }

        &.euro:before {
          background-image: url("../../assets/svg/euro.svg");
        }

        &.shop:before {
          background-image: url("../../assets/svg/shopping_cart.svg");
        }
        &.checked:before {
          box-shadow: inset 0 0 0 2px var(--primaryColor, cornflowerblue);
        }
      }

      span {
        width: 100%;
        text-align: center;
        margin-top: 0.84em;
        font-size: 0.84em;
        line-height: 1.2;
      }
    }

    display: flex;
    flex-flow: row wrap;
    align-content: center;
    align-items: flex-start;
    justify-content: space-between;
    min-height: unset;
    position: relative;
    width: calc(100% - 1em);
    padding: 0.5em 0;
    margin: 0.50em 0.50em 1em;

    > span {
      margin-bottom: 0.7em;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 25%;
    }

    > button {
      margin-bottom: 0.7em;
      cursor: pointer;
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center right 0.50em;
      background-size: auto 1.50em;
      font-size: 0.90em;
      text-align: center;
      box-shadow: inset 0 0 0 1px rgba(000, 000, 000, 0.15);
      background-color: white;
      padding: 0.75em 1.00em;
      flex: 0 0 10%;
      white-space: nowrap;
    }

    @media (max-width: 600px) {
      > span,
      > button {
        flex: 1 1 100%;
        margin-right: 16px;
      }
    }
  }

  &.Map {
    .dealer {
      box-shadow: none;
      margin: 0;
    }

    + .List {
      .dealer {
        height: unset;
      }
    }
  }

  .dealer {
    position: relative;
    margin: 0.50em;
    flex: 1 1 200px;
    padding: 1.00em 4.00em 3.00em 1.00em;
    background-color: rgba(255, 255, 255, 1.00);
    box-shadow: inset 0 0 0 1px rgba(000, 000, 000, 0.12);
    height: 100px;

    span.dealerIcon {
      position: absolute;
      top: 0.50em;
      right: 0.50em;
      display: inline-block;
      margin: 0;
      width: 2.50em;
      height: 2.50em;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 2.00em;

      &.shop {
        background-image: url("../../assets/svg/shopping_cart.svg");
      }

      &.euro {
        background-image: url("../../assets/svg/euro.svg");
      }

      &.location {
        background-image: url("../../assets/svg/location_on.svg");
      }
    }

    > span {
      display: block;
      font-family: var(--font200);

      + span {
        margin-top: 0.50em;
        font-size: 0.84em;
        font-family: var(--font100);
      }
    }

    a {
      text-decoration: none;
      position: absolute;
      bottom: 0.50em;
      right: 0.50em;
      display: inline-block;
      background-image: var(--image);
      background-repeat: no-repeat;
      background-position: center right 0.50em;
      background-size: auto 1.50em;
      font-size: 0.90em;
      text-align: center;
      box-shadow: inset 0 0 0 1px rgba(000, 000, 000, 0.12);
      padding: 0.75em 1.00em;
      padding-right: 2.00em;
      background-color: var(--primaryColor);
      color: var(--primaryTextColor);
      font-family: var(--font200);
    }
  }

  .dealer.ph {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    height: 0;
    opacity: 0 !important;
  }
}
