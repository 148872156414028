#app-root .hpm-orderSummary,
#itt-sl-hpm .hpm-orderSummary {
  width: 100.0%;
  max-width: 1000px;
  height: 100.0%;
  margin: 0 auto;

  .flex-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .hint {
    margin-bottom: 24px;
    background-color: hsl(33.3 100% 96.5%);
    border: 1px solid hsl(32.1 97.7% 83.1%);
    padding: 7px;
    font-size: 14px;
    font-family: var(--font100), Arial, sans-serif;
    line-height: 1.5;
  }

  .hpm-adressBox {
    color: #212832;
    margin: 0 0 15px;

    .hpm-title {
      font-style: normal;
      font-weight: bold;
      color: #212832;
      margin: 0 0 10px;
      display: block;
      font-family: var(--font100), Arial, sans-serif;
    }

    table {
      width: calc(100% - 12px);

      td {
        font-family: var(--font100), Arial, sans-serif;
      }
    }

  }


  .hpm-cart {
    font-family: var(--font100), Arial, sans-serif;
    list-style: none;
    padding: 0;

    tr, table, td {
      border: 0;
    }

  }

  .hpm-box {
    font-family: var(--font100), Arial, sans-serif;

    .hpm-edit {
      display: block;
      float: right;
      margin-top: -23px;
      font-family: var(--font100), Arial, sans-serif;

      &:before {
        content: "";
        background-image: url("../../../assets/svg/edit_note_FILL0_wght400_GRAD0_opsz24.svg");
        width: 25px;
        height: 24px;
        float: left;
        margin: -1px 5px 0 0;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }


  .hpm-cartContainer {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;

    .hpm-mainContentBox {
      flex: 2 1 400px;
      position: relative;
      margin: 12px 0 24px 24px;
      border-right: 1px solid #d9d9d9;
      padding: 0 24px 0 0;
      font-family: var(--font100), Arial, sans-serif;

      .hpm-box {
        margin-top: 24px;

        .hpm-title {
          width: 100.0%;
          margin-bottom: 12px;
          font-size: 1.05em;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          align-content: flex-end;
          align-items: flex-end;
          font-style: normal;
          font-weight: bold;
          color: #212832;
          font-family: var(--font100), Arial, sans-serif;
        }

        .hpm-shipping {
          table {
            width: 100%;

            td:nth-child(1) {
              width: 30%;
            }
          }
        }

        .hpm-paymentcarts {
          position: relative;
          display: inline-block;
          width: auto;
          min-width: 6.00em;
          margin: 0 12px 6px 0;
          padding: 0 0 0 46px;
          line-height: 30px;

          &:before {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 40px;
            height: 30px;
            content: '';
            box-shadow: inset 0 0 0 1px rgba(000, 000, 000, 0.15);
            border-radius: 4px;
            background-color: #f6f6f6;
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto 80.00%;
          }

          &.hpm-euro:before {
            background-image: url("../../../assets/svg/baseline-euro_symbol-24px.svg");
          }

          &.hpm-paypal:before {
            background-image: url("../../../assets/svg/paypal.png");
          }

          &.hpm-sepa:before {
            background-image: url("../../../assets/svg/SepaLogo.svg");
            background-size: auto 42% !important;
          }

          &.hpm-card:before {
            background-image: url("../../../assets/svg/CreditCard.svg");
            background-size: auto 66% !important;
          }

          &.hpm-bankWire:before {
            background-image: url("../../../assets/svg/bank_wire_symbol.svg");
          }

          &.hpm-przelewy24:before {
            background-image: url("../../../assets/svg/przelewy24-logo.svg");
            background-size: 100%;
          }
        }
      }

      .hpm-cartTitle {
        width: 100.0%;
        margin-bottom: 12px;
        font-size: 1.05em;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-content: flex-end;
        align-items: flex-end;
        font-style: normal;
        font-weight: bold;
        color: #212832;
        font-family: var(--font100), Arial, sans-serif;
      }
    }

    .hpm-dataSummaryBox {
      flex: 1 1 200px;
      margin: 12px 24px;
      position: relative;
    }

    .hpm-payment-notice {
      font-size: 0.8em;
      margin-top: 5px;
    }
  }


}
