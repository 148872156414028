#app-root .hpm-dealerList,
#itt-sl-hpm .hpm-dealerList  {
  flex: 1 1 100%;
  position: relative;
  width: 94.00%;
  align-items: flex-start;
  height: 100.0%;
  margin: 0 auto 2.00em;
  display: flex;

  .selectedProduct {
    position: relative;
    width: calc(100.0% - 2.50em - 2px);
    max-width: 360px;
    margin: 0 auto 2em;
    padding: 0.50em 2.00em 0.50em 0.50em;
    border-width: 1px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    border-color: rgba(000, 000, 000, 0.12);
    border-style: solid;
    border-width: 1px;
    text-align: left;
    background-color: #FFFFFF;

    img {
      max-height: 3.00em;
      max-width: 4em;
      margin: auto;

      + span {
        margin-left: 1.00em;
        width: calc(100% - 5em);
      }
    }
  }
}
