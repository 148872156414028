#app-root .hpm-emptyWishlist,
#itt-sl-hpm .hpm-emptyWishlist {
  min-height: 500px;
  font-family: var(--font100), Arial, sans-serif;

  span {
    margin-bottom: 2.5em;
    display: block;
    width: 100.0%;
    line-height: 1.20;
    font-style: normal;
    font-weight: normal;
    margin-top: 12px;
    font-family: var(--font100), Arial, sans-serif;
    text-align: center;
  }

  > div {
    text-align: center;
    white-space: pre-line;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .hpm-wishlistTitle {
    font-size: 14px;
    border-width: 0;
    border-bottom-width: 1px;
    border-style: solid;
    padding-bottom: 12px;
    text-align: center;
    width: 100%;
    border-color: rgb(224, 224, 224);

    .beforeWishlist {
      height: 66px;
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 66px;
      text-align: center;
      background-image: url("../../assets/svg/outline-star_border-24px.svg");
      display: block;

      img {
        display: initial;
        border: 0;
      }

      span {
        text-align: center;
      }
    }
  }

  .hpm-backButton {
    width: 100%;
    display: block;
    margin: 30px 0;
    font-style: normal;
    font-weight: normal;
    line-height: 42px;
    text-transform: uppercase;
    text-align: center;
    font-family: var(--font200), Arial, sans-serif;
    background-color: var(--primaryColor);
    color: var(--primaryTextColor);

  }

}
