#app-root,
#itt-sl-hpm {

  .hpm-spinnerButton {
    line-height: 32px;
    padding-top: 10px;
  }

  .hpm-paymentFacility {
    margin-top: 30px;
    min-height: 38px;

    button {
      color: var(--primaryTextColor);
    }
  }

  .hpm-fakeButton {
    width: 100%;
    display: block;
    margin: 30px 0;
    font-style: normal;
    font-weight: normal;
    line-height: 42px;
    text-transform: uppercase;
    text-align: center;
    font-family: var(--font200), Arial, sans-serif;
    background-color: white;
    border: 1px solid rgba(0,0,0,0.3);
    transition: all 0.1s ease-in-out;

    button {
      color: rgba(0,0,0,0.3);
      cursor: default;
    }
  }

  [disabled][type='button'] {
    cursor: unset;
  }

  .hpm-cartButton {
    cursor: pointer;
  }


  .hpm-onlyShowFirst {

    .spinnerBox {
      &:nth-child(2) {
        display: none;
      }
    }

  }

  .p24-paymentFields {
    width: 100%;
    display: none;
  }
}