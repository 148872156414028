.hpm-wishlistShare {
  width: 100%;
  height: 100%;
  background-color: #fff;
  color: #fff;
  position: relative;
  z-index: 3;
  overflow: hidden;

  &.appear, &.enter {
    opacity: 0;
  }
  &.enter-active, &.appear-active {
    opacity: 1;
    transition: opacity 500ms ;
  }
  &.exit, &.leave {
    opacity: 1;
  }
  &.exit-active, &.leave-active{
    opacity: 0;
    transition: opacity 500ms;
  }

  .hpm-close-sharePage {
    position: absolute;
    top: .7em;
    right: .7em;
    display: inline-block;
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 24px;
    background-image: url("../../assets/svg/baseline-close-24px.svg");
    background-color: unset;
    margin-right: unset;

    &:hover {
      cursor: pointer;
    }
  }

  .hpm-share-image {
    background-color: silver;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 56% auto;
    background-image: url("../../assets/svg/list_white.svg");
    margin-right: 30px;
    min-height: 75vh;
  }

  form {
    position: relative;
    padding: 30px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: stretch;
    color: rgba(033, 040, 050, 1.00);

    textarea {
      border: 0 solid rgba(000, 000, 000, 0.15);
      border-bottom-width: 2px;
      width: 100.0%;
      font-style: normal;
      font-weight: normal;
      font-size: 1.00em;
      height: 76px;
      padding-top: 12px;
      resize: vertical;
      -webkit-appearance: none;
      position: unset;
    }

    .wayOfContact input[type=radio] {
      display: none;
    }

    .wayOfContact {
      width: calc(100.0% + 12px);
      margin: 12px -6px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-content: stretch;
      align-items: stretch;

      input[type=radio] + label {
        height: 36px;
        flex: 1 1 10.00%;
        border-width: 1px;
        border-color: rgba(000,000,000,0.15);
        border-style: solid;
        margin: 0 6px;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: center;
        align-items: center;
      }

      input[type=radio] + label:hover {
        color: white;
        background: silver;
        cursor: pointer;
      }

      input[type=radio]:checked + label {
        color: white;
        background: gray;
      }
    }

    .hpm-conditions {
      input[type=radio], input[type=checkbox] {
        display: none;
      }

      label {
        position: relative;
        display: block;
        padding-left: 2.10em;
        line-height: 1.4;
        cursor: pointer;
      }

      input[type=checkbox] + label:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 1.50em;
        height: 1.50em;
        content: "";
        box-shadow: inset 0 0 0 1px rgba(000, 000, 000, 0.15);
        border-radius: 100.0%;
        background-color: silver;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80.0% auto;
      }

      input[type=checkbox]:checked + label:before {
        background-image: url("../../assets/svg/baseline-done-24px.svg");
        background-color: gray;
      }
    }

    .hpm-contact-send {
      margin-top: 24px;
      font-style: normal;
      font-weight: normal;
      padding: 10px 15px;
      text-transform: uppercase;
      letter-spacing: 0.15em;

      &:hover {
        cursor: pointer;
      }
    }

    input {
      height: 42px;
      width: 100.0%;
      border: 0 solid rgba(000, 000, 000, 0.15);
      border-bottom-width: 2px;

      &:focus {
        border: 0 solid rgba(000, 000, 000, 0.15);
        border-bottom-width: 2px;
      }
    }

    tbody tr td {
      padding: 0 6px 12px 0;
      vertical-align: top;
      border: 0;
      background-color: transparent;

      &.hpm-label {
        width: 65px;
      }
    }

    label {
      margin-bottom: unset;
      font-weight: unset;
    }

    h3 {
      flex: 0 0 100.0%;
      line-height: 1.20;
      font-style: normal;
      font-weight: 800;
      margin-bottom: unset;
      font-size: initial;
    }

    div {
      flex: 1 1 320px;
    }

    .hpm-error {
      color: red;

      textarea,
      input[type="checkbox"] + label::before,
      input {
        color: red;
        border: 1px solid red;
      }
    }
  }

}
