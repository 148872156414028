#app-root .hpm-brand-instance,
#itt-sl-hpm .hpm-brand-instance {
  font-family: var(--font100, Arial), Arial, sans-serif;
  width: 80%;
  height: 80%;
  padding: 10%;
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  position: relative;
  box-sizing: unset;
  -webkit-box-sizing: unset;
  -moz-box-sizing: unset;

  > div {
    width: calc(100% - 25px);
    height: calc(100% - 90px);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: stretch;
  }

  .hpm-brand-title {
    text-align: center;
    display: block;
    font-size: 32px;
    margin-bottom: 40px;
    line-height: 1.4;
    letter-spacing: 3px;
    text-transform: uppercase;
    height: auto;
  }

  .hpm-brands {
    width: 100%;
    box-sizing: border-box;
    max-width: 1200px;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    align-items: center;

    .hpm-brand {
      position: relative;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: stretch;
      align-items: stretch;
      cursor: pointer;
      min-width: 190px;
      max-width: 476px;
      background-color: white;
      width: 11.5rem;
      margin: 0.5rem;
      border: 1px solid rgba(156,172,186,.3);
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease 0s;

      @media(max-width: 520px) {
        flex: 1 1 95% !important;
      }

      .hpm-brandLogo {
        position: absolute;
        top: 10%;
        left: 10%;
        width: 80%;
        height: 80%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      img {
        opacity: 0;
        z-index: 0;
        width: 100%;
      }

      &:hover {
        box-shadow: 0 15px 20px rgba(156,172,186,.5);
        transform: translateY(-7px);
      }


    }

  }

}
