.hpm-modalHeader, .hpm-modalBody, .hpm-modalFooter {
  font-family: var(--font100), Arial, sans-serif;
}

.hpm-modalHeader {
  display: flex;
  border-bottom: #1d1d1b;
  border-bottom-style: solid;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 10px;
}
.hpm-modalHeader div {
  padding: 10px 0 10px 0;
}

.hpm-modalHeaderImage {
  height: inherit;
  width: auto;
  max-width: 270px;
  flex: 1 1 270px;

  img {
    max-width: 100%;
    height: 50px;
  }
}

.hpm-modalHeaderTitle {
  text-align: center;
  font-size: 28px;
  flex: 1 1 410px;
  vertical-align: center;
  margin-top: 10px;

  @media (max-width: 600px) {
    margin-top: 0;
    padding-top: 0 !important;
  }
}

.hpm-modalBody {
  margin-top: 30px;
  font-size: 20px;
}

.hpm-modalBody div {
  padding: 0 0 10px 30px;
}

.hpm-modalFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.hpm-modalFooterContent {
  display: flex;
  justifyContent: center
}

.hpm-modalFooterContent button {
  font-family: var(--font200), Arial, sans-serif;
  border-style: none;
  border-radius: 2px;
  background-color: var(--primaryColor);
  color: var(--primaryTextColor);
  font-style: normal;
  font-weight: normal;
  line-height: 42px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  width: 190px;
}