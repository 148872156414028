#app-root .hpm-navigation,
#itt-sl-hpm .hpm-navigation {
  + .hpm-productPage {
    margin: 20px var(--moduleBorderMargin);
  }
}

#app-root .hpm-productPage,
#itt-sl-hpm .hpm-productPage {
  &.hpm-productPage {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: stretch;
    box-sizing: border-box;
    float: left;
    background-color: var(--moduleBackground);
    width: calc(100% - var(--moduleBorderMargin) - var(--moduleBorderMargin));
  }

  @media (max-width: 599px) {
    padding: 24px 0;
  }

  .hpm-categoryProduct {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 15px;

    .hpm-ProductTitle {
      margin-left: 5px;
      font-size: 1.10em;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      font-style: normal;
      font-weight: normal;
      color: rgba(033, 040, 050, 1.00);
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      font-family: var(--font200), Arial, sans-serif;
    }
  }

  .hpm-sideBar {
    flex: 1 1 320px;
    margin: 12px;

    .hpm-menu {
      width: unset;

      .hpm-menuElement {
        width: unset;
      }
    }

    .hpm-shareAndContact {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      align-content: stretch;
      flex-flow: wrap;
      flex: 1 1 200px;
      width: 100%;
      padding-top: 7px;
      font-family: var(--font100), Arial, sans-serif;

      .hpm-shareWrapper {
        background-color: transparent;
        border: unset;

        .hpm-sharingLink {
          display: inline-block;
          width: auto;
          margin: 0 12px 6px 0;
          padding: 3px 0 6px 32px;
          cursor: pointer;
          background-repeat: no-repeat;
          background-position: left top;
          background-size: auto 24px;
          color: rgba(033, 040, 050, 1.00);
          list-style: none;
          border: 0;
          position: relative;
          background-image: url("../../assets/svg/share-24px.svg");
        }

        div {
          background-position-y: center;
          background-image: url("../../assets/svg/share-24px.svg");
          background-repeat: no-repeat;
          padding-left: 26px;
          height: 2.4em;
          display: flex;
          margin: 4px;
          align-items: center;

          &:hover {
            cursor: pointer;
            background-color: transparent;
            border: unset;
          }
        }
      }

      .hpm-questionWrapper {
        background-color: transparent;
        color: #212832;
        border: unset;

        div {
          background-position-y: center;
          background-image: url("../../assets/svg/baseline-chat_bubble_outline-24px_black.svg");
          display: inline-block;
          background-repeat: no-repeat;
          padding-left: 26px;
          min-height: 24px;
          padding-top: 1px;

          &:hover {
            cursor: pointer;
            background-color: transparent;
            border: unset;
          }
        }
      }
    }

    .contactWrapper {
      background-color: transparent;
      color: #212832;
      border: unset;

      div {
        background-position-y: center;
        display: inline-block;
        background-repeat: no-repeat;
        padding-left: 26px;
        min-height: 24px;
        padding-top: 5px;

        &.hpm-phone {
          background-image: url("../../assets/svg/phone.svg");
        }

        &.mail {

        }

        a {
          text-decoration: none;
          font-family: var(--font100), Arial, sans-serif;
        }
      }

    }

    .hpm-sideBarTitle {
      font-size: 1.17em;
      font-family: var(--font200), Arial, sans-serif;

      @media (max-width: 599px) {
        text-align: center;
      }
    }

    .hpm-sideBarArticleNumber {
      font-weight: normal;
      font-size: 14px;
      margin: 12px 0 24px 0;
      font-family: var(--font100), Arial, sans-serif;

      .hpm-ArticleNumberLabel {
        float: left;
        margin-right: 10px;
        font-family: var(--font100), Arial, sans-serif;
      }
    }

    .hpm-selectedLocation {
      font-weight: normal;
      font-size: 14px;
      margin: -10px 0 5px 20px;
      display: flex;
      justify-content: flex-end;

      .hpm-locationIcon {
        background-image: url("../../assets/svg/location_on.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        height: 16px;
        width: 16px;
        min-width: 16px;
      }
    }
  }
}
