.modalContents .hpm-payPalCheckout {
  min-height: 90px;
  p{
    margin-bottom: 0.9em;
  }
}

.zoid-outlet {
  width: 100% !important;
}

.paypal-buttons {
  min-height: 35px;
}