#app-root,
#itt-sl-hpm {
  .hpm-total {
    width: calc(100.0% - 24px);
    padding: 12px;
    background: #f6f6f6;
    margin-bottom: 12px;
    font-family: var(--font100), Arial, sans-serif;

    .hpm-confirmRequired {
      font-family: var(--font100), Arial, sans-serif;
      color: rgba(225, 50, 100, 1.00);

      small {
        display: block;
        margin-top: 8px;
        font-size: 0.9em;
        font-weight: normal;
        color: red;
        margin-left: 20px;
        white-space: nowrap;
      }
    }

    .hpm-error {
      margin-top: 30px;
      margin-bottom: -16px;

      small {
        color: red;
        font-weight: bold;
      }
    }

    table {
      border-collapse: collapse;
      width: 100%;

      tr:not(.hpm-sum) td {
        span {
          display: inline-block;
          width: 30.00%;
          font-size: 0.84em;
        }
      }

      td {
        padding: 3px 0;
        font-family: var(--font100), Arial, sans-serif;
      }

      .hpm-sum, .hpm-minOrderValue {
        border-top: 1px solid #d9d9d9;

        td {
          padding-top: 10px;
        }

        td:nth-child(2) {
          font-weight: bold;
        }
      }

      .hpm-minOrderValue {
        border-top: 0;
        td {
          padding-top: 20px;
          color: red;
          font-family: var(--font100), Arial, sans-serif;
          vertical-align: text-top;
          span {
            width: 95% !important;
            color: red;
            font-family: var(--font100), Arial, sans-serif;
          }
        }
      }
    }

    .hpm-totalTitle {
      width: 100.0%;
      margin-bottom: 12px;
      font-size: 1.05em;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-content: flex-end;
      align-items: flex-end;
      font-style: normal;
      font-weight: bold;
      color: #212832;
      font-family: var(--font100), Arial, sans-serif;
    }

    .hpm-paypal-buttons {
      margin-top: 0.9em;
    }


    .hpm-cartButton {
      width: 100%;
      display: block;
      margin: 25px 0 5px;
      font-style: normal;
      font-weight: normal;
      line-height: 42px;
      text-transform: uppercase;
      text-align: center;
      font-family: var(--font200), Arial, sans-serif;
      background-color: var(--primaryColor);
      color: var(--primaryTextColor);

      &[disabled] {
        cursor: not-allowed;
        opacity: .5;
      }
    }

  }

  .hpm-menu {
    width: 100%;

    .hpm-menuElement {
      height: 30px;

      input[type="checkbox"] {
        visibility: hidden;
      }

      &.hpm-conditions {
        margin: 5px 0 0 0;
        padding: 15px 0 0 0;
        border-top: 1px solid rgba(000, 000, 000, 0.15);

        .hpm-optionText {
          width: calc(100% - 32px);
        }
      }

      .hpm-optionText {
        float: left;
        line-height: 1;
        margin-left: 8px;
        padding: 4px 0 0 0;
        font-style: normal;
        font-weight: normal;
        font-size: 0.90em;

        &:hover {
          cursor: pointer;
        }
      }

      .hpm-circle {
        float: left;
        width: 1.50em;
        height: 1.50em;
        display: block;
        content: "";
        box-shadow: inset 0 0 0 1px rgba(000, 000, 000, 0.15);
        border-radius: 100.0%;
        background-color: white;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80.0% auto;
        min-width: 22px;

        &.hpm-selected {
          background-image: url("../../../assets/svg/baseline-check-24px.svg");
          background-color: black;
        }
      }
    }
  }

  .hpm-right {
    text-align: right !important;
    padding-right: 3% !important;
  }

}
