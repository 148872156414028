#app-root .hpm-App .hpm-carSelectionBar,
#itt-sl-hpm .hpm-App .hpm-carSelectionBar {
  min-height: 48px;
  background-color: var(--moduleBackground);
  position: relative;
  z-index: 3;

  .embla, .embla * {
    overflow: visible;
  }

  .embla__container > div {
    position: relative;
  }

  .hpm-vehicleModel {
    height: 1px;
    overflow: visible;
    margin: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    position: relative;
    z-index: 3;
  }

  .hpm-CarSelectorWrapper {
    z-index: 1;
    transition: max-height ease-in-out;
    max-height: 200px;
    margin: 0 0 20px;
    text-align: center;
    overflow: visible;

    &.exit-active {
      .hpm-vehicleModel {
        overflow: hidden;
        max-height: 0;
      }
    }

    &.enter,
    &.enter-active {
      max-height: 0;
    }

    &.exit-done, &.exit {
      max-height: 0;
      overflow: hidden;
    }

    .hpm-swimlaneViewPort {
      &.hpm-carSelectorViewport {
        padding-bottom: 240px;
        margin-bottom: -230px;

        .hpm-swimlaneNavigation {
          top: calc(50% - 120px);
        }
      }
    }
  }

  .hpm-infoCar {
    z-index: 2;
    display: flex;
    position: relative;
    top: 0;
    left: 50%;
    width: 450px;
    max-width: 50vw;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.15);
    background-color: white;
    border-style: solid;
    padding: 5px;
    text-align: center;
    transition: transform 0.5s linear;
    transform: translate(-50%, 0);
    font-family: var(--font100), Arial, sans-serif;

    &.enter, &.enter-active {
      transform: translate(-50%, -200%);
    }

    &.enter-done {
      transform: translate(-50%, 0);
    }

    &.exit, &.exit-active {
      opacity: 0;
    }

  }
  .hpm-selectionBarCarTitle {
    width: 100%;
    text-align: center;
  }


}

