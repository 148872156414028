$hpm-car-element-width: 160px;
$hpm-car-element-padding: 24px;

#app-root .hpm-App .hpm-carModelDropdown,
#itt-sl-hpm .hpm-App .hpm-carModelDropdown, {
  height: auto;
  border-width: 1px;
  overflow: inherit;
  position: absolute;
  flex-flow: column;
  z-index: 11;
  width: 200px;
  left: 50%;
  top: 140px;
  transform: translate(-50%, 0) !important;
  text-align: center;
  font-family: var(--font100, Arial), Arial, "Sans-Serif", serif;
  font-style: normal;
  font-weight: 100;
  border-color: rgba(0, 0, 0, 0.12);
  color: rgba(101, 101, 101, 1.00);
  border-style: solid;
  margin: -5px ($hpm-car-element-padding - 1px) 0 0;
  cursor: pointer;
  line-height: 1.40;
  font-size: 16px;
  align-content: stretch;
  align-items: stretch;
  transition: max-height 2s ease-in-out;

  .hpm-boxStart {
    position: absolute;
    top: calc(0% - 0.75em);
    left: calc(50% - 1em);
    width: 2em;
    height: 2em;
    transform: rotate(45.00deg);
    background: white;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.24);
  }

  .hpm-boxEnd {
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: white;
    white-space: pre-wrap;
  }

  div:first-of-type {
    border-top-width: 0;
  }

  .hpm-carModelItem {
    font-size: 0.85em;
    position: relative;
    z-index: 4;
    display: block;
    padding: 0.2em 0.5em;
    cursor: pointer;
    text-decoration: none;
    font-family: var(--font100, Arial), Arial, "Sans-Serif", serif;
    font-style: normal;
    font-weight: 100;
    border-color: rgba(0, 0, 0, 0.12);
    color: rgba(101, 101, 101, 1.00);
    border-style: solid;
    border-width: 0;
    margin: 0;
    text-align: center;
    line-height: 1.40em;
    border-top-width: 1px;

    &:hover {
      background-color: var(--secondaryColor, rgba(255, 0, 255, 1));
      color: var(--primaryTextColor, white);
    }
  }

}

.hpm-carModelDropdownPlaceholder {
  position: relative;
  margin: 0;
  flex-flow: column;
  opacity: 1;
  width: $hpm-car-element-width + $hpm-car-element-padding;
  transition: none;
}
