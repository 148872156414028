.hpm-couponCodeBox {
  &.hpm-couponCodeBox {
    color: #212832;
    margin: 0 0 15px;

    .hpm-title {
      font-style: normal;
      font-weight: bold;
      color: #212832;
      margin: 0 0 10px;
      display: block;
    }

    .error-message {
      color: red;
      margin-top: 10px;
      display: flex;
    }

    .hpm_couponWrapper {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;

      .hpm-coupon-code {
        flex-grow: 1;
        min-width: 100px;
        width: 100%;
        height: 30px;
        padding: 6px;
        box-shadow: inset 0 0 0 1px rgba(000, 000, 000, 0.15);
        border: 0;
        font-family: Arial, sans-serif;

        &:disabled {
          background-color: rgb(246, 246, 246);
          box-shadow: none;
        }
      }

      .hpm-couponButton {
        background-color: #f6f6f6;
        border-bottom: 1px solid rgba(000, 000, 000, 0.15);
        border-right: 1px solid rgba(000, 000, 000, 0.15);
        border-top: 1px solid rgba(000, 000, 000, 0.15);
        padding: 10px;
        color: #000000;
        font-family: sans-serif;
        flex-grow: 1;
        text-align: center;
        cursor: pointer;

        &.reset {
          padding-left: 12px;
          padding-right: 12px;
        }
      }

      @media (max-width: 850px) {
        flex-wrap: wrap;

        .hpm-coupon-code {
        }
        .hpm-couponButton {
          width: 100%;
        }

      }
    }

  }
}
