.hpm-App {
  .hpm-categoryTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 1.20em;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: black;
    background-color: transparent;
    font-family: var(--font200), Arial, sans-serif;
    margin: 0 0.5em 18px;

    &:hover {
      cursor: pointer;
    }

    button {
      padding: 0;
    }
  }

  .hpm-categorySubTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 0.90em;
    letter-spacing: 0;
    text-transform: none;
    position: relative;
    min-height: 20px;

    &:hover {
      cursor: pointer;
    }
  }
}

.hpm-App .swimLane + .hpm-productGroup {
  margin-top: 38px;
}

.hpm-App .hpm-productGroup {
  text-decoration: none;
  text-transform: uppercase;
  font-family: var(--font200), Arial, sans-serif;
  width: calc(100% - 1em);
  font-size: 1.2em;
  letter-spacing: 0.03em;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0.5em 12px;
  flex-flow: row wrap;

  &.noSubtitle {
    align-items: center;

    span:nth-child(1) > span {
      display: none;
    }
  }


  > span:nth-child(1) {
    flex: 1 1 300px;

    > span {
      font-family: var(--font100), Arial, courier, sans-serif;
      font-weight: normal;
      display: block;
      margin-top: 6px;
      font-size: 0.90em;
      letter-spacing: 0;
      text-transform: none;
    }
  }

  > span:nth-child(2) {
    font-family: var(--font100), Arial, sans-serif;
    font-weight: normal;
    font-size: 0.84em;
    text-transform: none;
    flex: 1 1 300px;
    text-align: right;
    min-width: 300px;
  }
}
