.hpm-gridScrollWrapper {
  width: 100%;
  overflow: auto;
  padding-bottom: 15px;
}

.hpm-gridScrollWrapperContainer {
  width: 100%;
  overflow: hidden;
  height: 275px;
  top: 0;
  transition: top 0.5s;

  &.hpm-car {
    height: 110px;
    transition: max-height 0.5s linear;
    max-height: 110px;
  }

  &.hpm-slideUp {
    max-height: 0;

    @media (max-width: 599px) {
      max-height: 0;
    }
  }

  position: relative;

  .hpm-scrollLeft {
    top: 50%;
    position: absolute;
    z-index: 2;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    opacity: 0.84;
    border-radius: 100.0%;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 24px;
    background-color: white;
    left: -20px;
    box-shadow: inset 0 0 0 1px rgba(000, 000, 000, 0.15);
    background-image: url("../assets/svg/baseline-keyboard_arrow_left-24px.svg");
    background-position: center left 13px;
  }

  .hpm-scrollRight {
    top: 50%;
    position: absolute;
    z-index: 2;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    opacity: 0.84;
    border-radius: 100.0%;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 24px;
    background-color: white;
    right: -20px;
    box-shadow: inset 0 0 0 1px rgba(000, 000, 000, 0.15);
    background-image: url("../assets/svg/baseline-keyboard_arrow_right-24px.svg");
    background-position: center right 13px;
  }
}

@media (any-pointer: coarse) {
  .hpm-scrollLeft {
    display: none;
  }
  .hpm-scrollRight {
    display: none;
  }
}

.hpm-gridList {
  > div {
    padding: 0 0.5em;
    position: relative;
    height: auto;
    display: grid;
    grid-column-gap: 24px;
    column-gap: 24px;
    row-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    justify-content: center;

    .hpm-gridElement {
      margin-right: 6px;
    }
  }
}

.hpm-infoCar {

  .hpm-close {
    background-image: url("../assets/svg/baseline-close-24px.svg");
    width: 15px;
    height: 15px;
    margin-top: 2px;
    background-size: 15px;
    margin-left: 4px;
  }

  &.hpm-slideDown {
    top: 0;
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 599px) {
    top: -175px;
  }
}
