#app-root .hpm-cart,
#itt-sl-hpm .hpm-cart {
  width: 100.0%;
  max-width: 1000px;
  height: 100.0%;
  margin: 0 auto;
  list-style: none;
  padding: 0;
  font-family: var(--font100), Arial, sans-serif;

  table {
    td {
      font-family: var(--font100), Arial, sans-serif;
    }
  }

  .hpm-cartContainer {
    position: relative;
    width: 100.0%;
    margin-bottom: 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: flex-start;
    border-bottom: 1px solid #d9d9d9;
    font-weight: normal;


    .hpm-productName {
      width: 100%;

      tr td:first-child {
        width: 50%
      }
    }

    a {
      text-decoration: none;
      font-weight: bold;
      color: rgba(033, 040, 050, 1.00);
    }

    .hpm-cartImage {
      height: 120px;
      width: 120px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      position: relative;

      img {
        max-width: calc(120px - 12px);
        max-height: calc(120px - 12px);
      }
    }

    .hpm-tables {
      flex: 1 1 120px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-content: flex-start;
      align-items: flex-start;
      font-size: 0.84em;

      .hpm-left {
        flex: 4 1 0;
      }

      .hpm-right {
        flex: 2 1 150px;
      }

      td {
        padding: 5px 0;
        font-family: var(--font100), Arial, sans-serif;
      }
    }

    span {
      margin: 0 6px 0 0;
    }

    .hpm-price {
      font-weight: bold;
    }

    .hpm-price {
      font-weight: normal;
      text-decoration: line-through;

      & + .hpm-sale,
      & + .hpm-discount {
        font-weight: bold;
      }
    }

    .hpm-sale {
      color: rgba(225, 50, 100, 1.00);
    }


    input[type="number"] {
      height: 30px;
      width: 40px;
      text-align: center;
      padding: 0 6px;
      background-color: var(--moduleBackground, white);
      font-family: var(--font100), Arial, sans-serif;
      -webkit-box-shadow: none;
      display: inherit;
    }
  }

  .hpm-box {
    .hpm-edit {
      display: block;
      float: right;
      margin-top: -30px;
      font-family: var(--font100), Arial, sans-serif;

      &:before {
        content: "";
        background-image: url("../../../assets/svg/baseline-redo-24px.svg");
        width: 25px;
        height: 16px;
        float: left;
        margin: -2px 5px 0 0;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .hpm-cartContainer {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;

    .hpm-mainContentBox {
      flex: 2 1 400px;
      position: relative;
      margin: 12px 0 24px 24px;
      border-right: 1px solid #d9d9d9;
      padding: 0 24px 0 0;

      .hpm-box {
        margin-top: 24px;

        .hpm-title {
          width: 100.0%;
          margin-bottom: 12px;
          font-size: 1.05em;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          align-content: flex-end;
          align-items: flex-end;
          font-style: normal;
          font-weight: bold;
          color: #212832;
          font-family: var(--font100), Arial, sans-serif;
        }

        .hpm-shipping {
          table {
            width: 100%;

            td:nth-child(1) {
              width: 30%;
            }
          }
        }

        .hpm-paymentcarts {
          white-space: nowrap;
          position: relative;
          display: inline-block;
          width: auto;
          min-width: 6.00em;
          margin: 0 12px 6px 0;
          padding: 0 0 0 46px;
          line-height: 30px;
          max-width: 100%;

          &:before {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 40px;
            height: 30px;
            content: '';
            box-shadow: inset 0 0 0 1px rgba(000, 000, 000, 0.15);
            border-radius: 4px;
            background-color: #f6f6f6;
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto 80.00%;
          }

          &.hpm-euro:before {
            background-image: url("../../../assets/svg/abholung-web.svg");
            background-size: 70%;
          }

          &.hpm-paypal:before {
            background-image: url("../../../assets/svg/paypal.png");
          }

          &.hpm-bankWire:before {
            background-image: url("../../../assets/svg/bank_wire_symbol.svg");
          }

          &.hpm-przelewy24:before {
            background-image: url("../../../assets/svg/przelewy24-logo.svg");
            background-size: 100%;
          }

          &.hpm-sepa:before {
            background-image: url("../../../assets/svg/SepaLogo.svg");
            background-size: auto 42% !important;
          }

          &.hpm-card:before {
            background-image: url("../../../assets/svg/CreditCard.svg");
            background-size: auto 66% !important;
          }
        }
      }

      .hpm-cartTitle {
        width: 100.0%;
        margin-bottom: 12px;
        font-size: 1.05em;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-content: flex-end;
        align-items: flex-end;
        font-style: normal;
        font-weight: bold;
        color: #212832;
        font-family: var(--font100), Arial, sans-serif;
      }
    }

    .hpm-cartErrorContent {
      width: 60%;
      margin: 12px 24px;
      position: relative;

      .hpm-cartErrorSpacer {
        height: calc(386px / 2 - 50px);
      }

      .hpm-cartErrorMessage {
        text-align: center;
        padding: 5px 0 5px 0;
      }
    }

    .hpm-dataSummaryBox {
      tr, table, td {
        border: 0;
        background: #f6f6f6;
      }

      flex: 1 1 200px;
      margin: 12px 24px;
      position: relative;
    }

  }
  .flex-container {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
  }
    @media (max-width: 768px) {
      .flex-container {
        grid-template-columns: repeat(2, 1fr);;
      }
    }

  .hint-cart {
    margin-bottom: 24px;
    background-color: hsl(33.3 100% 96.5%);
    border: 1px solid hsl(32.1 97.7% 83.1%);
    padding: 7px;
    font-size: 14px;
    font-family: var(--font100), Arial, sans-serif;
    line-height: 1.5;
  }
}
