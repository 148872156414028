#app-root .hpm-CheckoutSlider,
#itt-sl-hpm .hpm-CheckoutSlider {
  font-family: var(--font100), Arial, sans-serif;

  tr, table, td {
    border: 0;
    color: inherit;
    background: inherit;
  }

  .hpm-emptyCartText {
    text-align: center;
  }

  .hpm-loadingSpinner {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
  }

  &.hpm-CheckoutSlider {
    font-family: var(--font100), Arial, sans-serif;
    background-color: var(--moduleBackground, white);
    width: calc(100.0% - 0px);
    margin: 20px auto;
    position: relative;
  }

  &.hpm-emptyCart {
    min-height: 500px;

    span {
      margin-bottom: 2.5em;
      display: block;
      width: 100.0%;
      line-height: 1.20;
      font-style: normal;
      font-weight: normal;
      margin-top: 12px;
    }

    > div {
      text-align: center;
      white-space: pre-line;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .hpm-CheckoutNavigation {
    margin: 0 24px;
    display: none;
    justify-content: space-between;
    font-family: var(--font100), Arial, sans-serif;

    .hpm-Step {
      display: flex;
      align-items: center;
    }


    @media (max-width: 627px) {
      display: flex;
    }

    > button {
      background-image: url("../../assets/svg/baseline-keyboard_arrow_left-24px.svg");
      position: relative;
      width: 4.2em;
      height: 39px;
      background-repeat: no-repeat;
      background-position: left center;
      background-size: auto 1.80em;
      border-width: 0;
      border-color: rgba(0, 0, 0, 0.15);
      border-style: solid;
      text-align: right;
    }
  }

  .hpm-CheckoutProgressBar {
    width: calc(100.0% - 48px);
    height: 92px;
    padding: 0;
    margin: 0 24px 12px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    border-bottom: 1px solid silver;

    @media (max-width: 627px) {
      display: none
    }

    .hpm-bar {
      position: absolute;
      top: 40px;
      left: calc(10.00% + 12px);
      width: calc(80.00% - 24px);
      height: 4px;
      background: silver;
      overflow: hidden;

      > div {
        position: absolute;
        top: 0;
        left: 0;
        height: 100.0%;
        background: black;
        width: 100%;
        transition: all 150ms ease-in-out 0ms;
      }
    }

    button {
      position: relative;
      flex: 1 1 20.00%;
      height: calc(100.0% - 24px);
      margin-bottom: 32px;
      font-size: 10px;
      text-transform: uppercase;
      text-align: center;
      padding-top: calc(30px + 5em);
      padding-bottom: 12px;
      border-bottom-width: 1px;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-end;
      align-items: flex-end;
      box-sizing: border-box;
      font-family: var(--font100), Arial, sans-serif;

      &:focus {
        outline: none;
      }

      &:nth-of-type(1)::before {
        content: '1'
      }

      &:nth-of-type(2)::before {
        content: '2'
      }

      &:nth-of-type(3)::before {
        content: '3'
      }

      &:nth-of-type(4)::before {
        content: '4'
      }

      &:nth-of-type(5)::before {
        content: '5'
      }

      &::before {
        background: silver;
        position: absolute;
        top: 30px;
        left: calc(50.00% - 11.5px);
        width: 23px;
        height: 23px;
        border-radius: 100.0%;
        font-size: 10px;
        line-height: 0;
        font-weight: bold;
        color: white;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: center;
        align-items: center;
        content: '';
        font-family:var(--font100), Arial, sans-serif;
      }

      &.hpm-active::before {
        background: black;
      }
    }
  }

  .hpm-emptyCheckoutButton {
    flex: 1 1 80%;
    display: block;
    margin: 30px 0 0 0;
    font-style: normal;
    font-weight: normal;
    line-height: 42px;
    height: 42px;
    text-transform: uppercase;
    text-align: center;
    overflow: hidden;
    box-sizing: unset;
    padding-bottom: 0;
    padding-top: 0;
    font-family:var(--font200), Arial, sans-serif;
    background-color: var(--primaryColor);
    color: var(--primaryTextColor);

    &.back {
      width: 100%;
    }
  }
  .hpm-legalTerms {
    font-size: 0.72em;
    a {
      text-decoration-line: underline;
    }
  }

}

#file-download-button.file-download-button {
  font-family: var(--font200), Arial, sans-serif;
  border-style: none;
  border-radius: 2px;
  padding: 0px 20px 0px 20px;
  background-color: var(--primaryColor);
  color: var(--primaryTextColor);
  font-style: normal;
  font-weight: normal;
  line-height: 42px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
}
