.hpm-locationModalLink {
  color: inherit !important;
  font-family: inherit !important;
  text-decoration: inherit !important;
  cursor: pointer;
  text-align: center !important;
}

#hpm-locationSelector {
  .hpm-locationsHead {
    padding: 0 0 16px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    color: #000;

    .hpm-modalClose {
      position: absolute;
      right: 7px;
      top: 7px;
      width: 36px;
      height: 36px;
      border: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 24px;
      background-image: url("../../assets/svg/baseline-close-24px.svg");
      background-color: rgba(255, 255, 255, 0.6);

      &:hover {
        cursor: pointer;
      }
    }

    .hpm-locationsHeadline {
      font-family: var(--font100), Arial, sans-serif;
      font-size: 1.17em;
      font-weight: 700;
    }

  }

  .hpm-locationsBody {
    max-width: 100%;
    padding: 0;
    margin: 0;
    overflow: visible;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap;
    color: #000;

    .hpm-locations {
      @media(min-width: 852px) {
        max-width: 750px;
        margin: 24px auto 0;

        .hpm-locationImage {
          width: 10em;
        }
      }
      max-width: 400px;
      margin: 0 auto;
      padding: 8px 0;
      width: 100%;

      .hpm-location {
        border-bottom: 1px solid rgb(207, 203, 202);
        margin-bottom: 36px;

        &:last-child {
          border-bottom: 0 solid white;
          margin-bottom: 0;
          .hpm-locationDetails {
            padding-bottom: 0;
          }
        }

        .hpm-locationHeadline {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: space-between;

          .hpm-locationName span {
            margin: 40px 0 24px;
            font-family: var(--font100), Arial, sans-serif;
            font-size: 1.17em;
            font-weight: 400;
          }
          .hpm-locationAvailability {
            display: flex;
            align-items: baseline;

            .hpm-locationAvailabilityIndicator span {
              display: inline-block;
              height: 8px;
              width: 8px;
              margin-right: 8px;
              border-radius: 50%;
              background: rgb(112, 193, 24) none repeat scroll 0% 0%;
              line-height: 1.15;
              margin-left: 3px;
            }
            .hpm-locationAvailabilityText {
              flex: 1 1 auto;
              position: relative;
              color: rgb(39, 36, 34);
              font-size: 14px;
              font-family: var(--font100), Arial, sans-serif;
              font-weight: 400;
              font-style: normal;
              letter-spacing: normal
            }

          }
        }
        .hpm-locationDetails {
          @media(max-width: 852px) {
            flex-wrap: wrap;
          }
          flex-direction: row;
          flex-wrap: nowrap;
          padding: 16px 0 40px;
          display: flex;
          justify-content: space-between;

          .hpm-locationImage {
            width: 11em;
            overflow: hidden;
            display: flex;
            align-content: center;
            justify-content: center;

            .hpm-garageImageContainer {
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              max-height: 160px;
              max-width: 176px;
              width: 150px;
              height: 93px;
            }

            img {
              max-width: 100%;
              max-height: 100px;
            }
          }

          .hpm-locationAddress {
            display: flex;
            padding: 4px 0;
            margin: 0 0.5em 0 0;
            justify-content: center;
            flex-grow: 1;

            .hpm-locationAddressIcon {
              position: relative;
              top: 2px;
              display: flex;

              img {
                height: 16px;
                width: 16px;
              }
            }

            .hpm-locationAddressText {
              margin: 0 0 0 8px;
              color: rgb(39, 36, 34);
              font-size: 14px;
              line-height: 20px;
              font-family: var(--font100), Arial, sans-serif;
              font-weight: 400;
              letter-spacing: normal;

              .hpm-locationDistance {

              }
            }
          }
          .hpm-selectButton {
            max-width: 270px;
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0 24px;
            outline: currentcolor none medium;
            position: relative;
            align-self: flex-start;
            display: flex;
            font-style: normal;
            font-weight: normal;
            line-height: 42px;
            text-transform: uppercase;
            text-align: center;
            overflow: hidden;
            box-sizing: unset;
            background-color: var(--primaryColor);
            color: var(--primaryTextColor);
            transition: all 0.2s ease-in-out;
            font-family: var(--font200), Arial, sans-serif;
            cursor: pointer;
            box-shadow: none;
            border: 0;
            margin: 5px 0;

            &[disabled] {
              cursor: default;
              opacity: .5;
            }
          }
        }

      }

    }
  }
}
