.hpm-searchBar {
  &.hpm-searchBar {
    height: 100.0%;
    border-width: 0;
    width: 100%;
    padding: unset;
    display: flex;
    flex-flow: row-reverse;
    justify-content: unset;
    align-content: unset;
    align-items: unset;
    margin: 0;
    font-family: var(--font100) !important;

    input {
      width: calc(100% - 39px);
      float: left;
      height: 100%;
      border: 0;
      display: block;
      text-align: left;
      padding: 5px;
      box-sizing: border-box;
      box-shadow: unset;
      font-family: var(--font100) !important;
    }

    #hpm-search {
      position: relative;
      width: 39px;
      height: 100.0%;
      background-repeat: no-repeat;
      background-size: auto 1.80em;
      background-position: left 6px center;
      border-radius: unset;
      border-width: 0;
      box-sizing: border-box;
    }
  }
}
