#app-root .hpm-orderSummaryCartItem,
#itt-sl-hpm .hpm-orderSummaryCartItem {
  position: relative;
  width: 100.0%;
  margin-bottom: 10px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: flex-start;
  border-bottom: 1px solid #d9d9d9;
  font-weight: normal;
  font-family: var(--font100), Arial, sans-serif;


  .hpm-productName {
	width: 100%;

	tr td:first-child {
	  width: 50%
	}
  }

  a {
	text-decoration: none;
	font-weight: bold;
	color: rgba(033, 040, 050, 1.00);
  }

  .hpm-cartImage {
	height: 120px;
	width: 120px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	position: relative;

	img {
	  max-width: calc(120px - 12px);
	  max-height: calc(120px - 12px);
	}
  }

  .hpm-tables {
	flex: 1 1 120px;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-content: flex-start;
	align-items: flex-start;
	font-size: 0.84em;
	font-family: var(--font100), Arial, sans-serif;

	.hpm-left {
	  flex: 4 1 0;
	}

	.hpm-right {
	  flex: 2 1 150px;
	}

	td {
	  padding: 5px 0;
	  font-family: var(--font100), Arial, sans-serif;
	  vertical-align: top;
	}
  }

  span {
	margin: 0 6px 0 0;
  }

  .hpm-price {
	font-weight: bold;
  }

  .hpm-price {
	font-weight: normal;
	text-decoration: line-through;

	& + .hpm-sale,
	& + .hpm-discount {
	  font-weight: bold;
	}
  }

  .hpm-sale {
	color: rgba(225, 50, 100, 1.00);
  }


  input {
	height: 30px;
	width: 40px;
	text-align: center;
	padding: 0 6px;
  }

	.summary-price {
		font-family: var(--font200), Arial, sans-serif;
		font-size: 14px;
	}

	.unit-price {
		font-family: var(--font100), Arial, sans-serif;
		font-size: 10px;
	}
}


