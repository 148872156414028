.contact-info-tab {
  display: flex;
  gap: 30px;
}

.contact-info-tab h3 {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 2px solid currentColor;
  padding-bottom: 5px;
}

@media (max-width: 768px) {
  .contact-info-tab {
    flex-direction: column;
  }
}

.trademark {
  margin-top: 0.5em !important;
}
