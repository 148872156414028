#app-root .hpm-App,
#itt-sl-hpm .hpm-App {

  .hpm-priceBox {
	width: 100%;
	padding: 15px;
    border: 1px solid rgba(000, 000, 000, 0.15);
	box-sizing: border-box;

	.hpm-infoBoxPrice {
	  font-size: 0.84em;
	  color: rgba(000, 000, 000, 1.00);
	  font-family: var(--font100), Arial, sans-serif;
	}

	.hpm-priceBoxLabel {
	  float: right;

	  .hpm-price {
		font-size: 1.20em;
		font-style: normal;
		font-weight: normal;
		color: rgba(000, 000, 000, 1.00);
		text-align: right;
		font-family: var(--font200), Arial, sans-serif;

		&.hpm-stroke {
		  text-decoration: line-through;
		  font-size: 0.90em;
		  font-family: var(--font100), Arial, sans-serif;
		}
	  }

	  .hpm-retailPrice {
		font-size: 1.20em;
		font-style: normal;
		font-weight: normal;
		text-align: right;
		font-family: var(--font200), Arial, sans-serif;
	  }

		.unit-price {
			font-size: 10px;
			font-family: var(--font100), Arial, sans-serif;
			text-align: right;
			margin-bottom: 6px;
		}

	  .hpm-info {
		font-size: 14px;
		font-style: normal;
		font-weight: normal;
		text-align: right;
		font-family: var(--font100), Arial, sans-serif;

		> span {
		  display: block;
		}
	  }

	  .hpm-discount {
		font-size: 14px;
		font-style: normal;
		font-weight: normal;
		text-align: right;
		font-family: var(--font100), Arial, sans-serif;

		&.hpm-info {
		  font-size: 10px;
		  font-family: var(--font100), Arial, sans-serif;
		}
	  }

	  .hpm-sale {
		font-size: 14px;
		color: rgba(225, 50, 100, 1.00);
		font-style: normal;
		font-weight: normal;
		text-align: right;
	  }
	}

	.hpm-menu {
	  width: 100%;
	  float: left;
	  font-family: var(--font100), Arial, sans-serif;

	  .hpm-menuElement {
		height: 30px;
		visibility: var(--priceBoxCheckboxVisibility);

		input[type="checkbox"] {
		  visibility: hidden;
		}

		.hpm-optionText {
		  float: left;
		  line-height: 1;
		  margin-left: 8px;
		  padding: 4px 0 0 0;
		  font-style: normal;
		  font-weight: normal;
		  font-size: 0.90em;
		  font-family: var(--font100), Arial, sans-serif;

		  &:hover {
			cursor: pointer;
		  }
		}

		.hpm-circle {
		  float: left;
		  width: 1.50em;
		  height: 1.50em;
		  display: block;
		  content: "";
		  box-shadow: inset 0 0 0 1px rgba(000, 000, 000, 0.15);
		  border-radius: 100.0%;
		  background-color: white;
		  background-repeat: no-repeat;
		  background-position: center;
		  background-size: 80.0% auto;
		  cursor: pointer;

		  &.hpm-selected {
			background-image: url("../../assets/svg/baseline-done-24px.svg");
			background-color: gray;
		  }
		}

	  }

	}


	.hpm-actions {
	  display: flex;
	  margin: 0 0 0.6em 0;

	  .hpm-hidden {
		display: none;
	  }
	}


	.hpm-cartButton {
	  flex: 1 1 80%;
	  display: block;
	  margin: 30px 0 0 0;
	  font-style: normal;
	  font-weight: normal;
	  line-height: 42px;
	  text-transform: uppercase;
	  text-align: center;
	  overflow: hidden;
	  box-sizing: unset;
	  padding-bottom: 0;
	  padding-top: 0;
	  background-color: var(--primaryColor);
	  color: var(--primaryTextColor);
	  transition: all 0.2s ease-in-out;
	  font-family: var(--font200) , Arial, sans-serif;
		cursor: pointer;
		box-shadow: none;

		a {
			text-decoration: none;
			color: inherit;
			font-family: inherit;
		}

	  &.back {
		width: 100%;
	  }

		&.inverted {
			color: var(--primaryTextColor);
			background-color: var(--primaryColor);
			border: 1px solid var(--primaryColor);
		}

	  &.bookmarks {
		display: flex;
		justify-content: center;
		flex-flow: row nowrap;

        @media (max-width: 360px) {
          span:first-child{
            display: none;
          }
        }


		> * {
		  transition: all 0.2s ease-in-out;
		}

		&.isShopEnable{
		  background-color: #F2F2F2;
		}

		&.isShopDisable {
			background-color: var(--primaryColor);
		  }

		span {
		  display: block;
			color: var(--textPrimaryColor);

		  &.ico {
			height: 40px;
			width: 40px;
			background-repeat: no-repeat;
			background-position: center;
		  }
		}
	  }

	  + .bookmarks {
		flex: unset;
		height: 42px;
		width: 42px;
		margin-left: 15px;

		span {
		  &.ico {
			display: block;
		  }

		  display: none;
		}
	  }
	}
  }
}


