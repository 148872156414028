#app-root .hpm-sharingIconBox,
#itt-sl-hpm .hpm-sharingIconBox {

  &.hpm-sharingIconBox {
	font-style: normal;
	font-weight: normal;
	max-height: 200px;
	opacity: 1;
	visibility: visible;
	position: absolute;
	z-index: 2;
	top: calc(100.0% + 1.00em);
	height: auto;
	left: 0;
	border-width: 1px;
	border-style: solid;
	color: rgba(033, 040, 050, 1.00);
	border-color: rgba(000, 000, 000, 0.15);
	font-size: 1.00em;
	cursor: pointer;
	transition: all 150ms ease-in-out 0ms;
  }

  &::before {
	box-shadow: inset 0 0 0 1px rgba(000, 000, 000, 0.15);
	content: '';
	position: absolute;
	top: calc(0.0% - 1.00em);
	left: 1.00em;
	width: 2.0em;
	height: 2.0em;
	transform: rotate(45.00deg);
	background: white;
  }

  &::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100.0%;
	height: 100.0%;
	background: white;
  }

  a+a {
    border-top-width: 1px;
  }

  a {
    list-style: none;
    text-decoration: none;
	font-size: 0.90em;
	font-style: normal;
	font-weight: normal;
	position: relative;
	z-index: 2;
	height: 2.0em;
	margin: 0 1.00em;
	padding-left: 2.40em;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
    color: rgba(033,040,050,1.00);
    border: 0;
    border-style: solid;
    border-color: rgba(000,000,000,0.15);

    &.first {
      margin-top: 0.50em;
    }
    &.last {
      margin-bottom: 0.50em;
    }

    &::before {
      content: '';
      list-style: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 100.0%;
      width: 2.00em;
      background-repeat: no-repeat;
      background-position: center;
    }

    &[title="Facebook"]::before {
      background-size: auto 70.00%;
      background-image: url('../../../assets/svg/facebook.svg');
    }
    &[title="WhatsApp"]::before {
      background-size: auto 100.00%;
      background-image: url('../../../assets/svg/whatsapp.svg');
    }
    &[title="Pinterest"]::before {
      background-size: auto 70.00%;
      background-image: url('../../../assets/svg/pinterest.svg');
    }
    &[title="Twitter"]::before {
      background-size: auto 60.00%;
      background-image: url('../../../assets/svg/twitter.svg');
    }
	&[title="E-Mail"]::before {
	  background-size: auto 80.00%;
	  background-image: url('../../../assets/svg/baseline-mail_outline-24px.svg');
	}
	&[title="LinkedIn"]::before {
	  background-size: auto 70.00%;
	  background-image: url('../../../assets/svg/linkedin.svg');
	}

  }

}
