html, body {
  padding: 0;
  min-height: 100vh;
  overflow: auto;
}

#itt-sl-hpm-preview:not(#itt-sl-hpm) {
  overflow: hidden;
  height: 100%;
}

#itt-sl-hpm + #itt-sl-hpm-preview {
  overflow: auto;
}

.page-middle {
  width: 100%;
  margin: -0.50em;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
  padding-bottom: 70px;
}

#itt-sl-hpm-preview header {
  background-color: #fff;
}

#itt-sl-hpm-preview.dealerSearch {
  background-color: #FAFAFA;
  min-height: calc(100%);
  padding-bottom: 70px;
}
